import React from 'react'
import { createRoot } from 'react-dom/client';

import UserActivityGraph from './UserActivityGraph';

document.addEventListener('DOMContentLoaded', () => {
  const graphElements = document.getElementsByClassName('user-activity-graph');
  Array.from(graphElements).forEach(el => {
    const root = createRoot(el);
    root.render(<UserActivityGraph callCenterId={el.getAttribute('data-id')} />);
  })
})
