import React, { useEffect, useRef } from 'react'

export const StatBox = ({title, children, border = false, isLoading = false}) => {
  return <div className={border && "border-t border-gray-200 md:border-0 md:border-l" || ""}>
    <div className="px-4 py-5 sm:p-6">
      <dl>
        <dt className="text-base leading-6 font-normal text-gray-900">
          {title}
        </dt>
        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
          {isLoading ? <span className="ml-2 text-sm leading-5 font-medium text-gray-500">Loading...</span> : children}
        </dd>
      </dl>
    </div>
  </div>;
};

export function useInterval(callback, delayInSeconds) {
  const delay = delayInSeconds * 1000;
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Execute right now
  useEffect(() => {
    savedCallback.current()
  }, []);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
