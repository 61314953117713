import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import { usePaginatedQuery } from 'react-query';
import DataTable from '../components/datatable';
import { TextColumnFilter, GlobalFilter } from '../components/filters';
import { numbersEndpoint } from '../endpoints';
import TableLoader from '../components/datatable/TableLoader';
import { ToastContainer } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  insertUrlParam,
  queryParamsUrl,
  getQueryParam,
} from '../utils/queryParamsHelper';
import Highlighter from 'react-highlight-words';
import CopyUrlButton from '../components/CopyUrlButton';
import RefreshButton from '../components/RefreshButton';
import PauseButton from '../components/PauseButton';
import CleanFiltersButton from '../components/CleanFiltersButton';
import DownloadButton from '../components/DownloadButton';
import createPath from '../utils/pathHelper';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-time-picker/assets/index.css';
import { setAll } from '../utils/objHelper';
import moment from 'moment';

const TooltipWrapper = ({ text, searchTerms = [], maxLength = 20 }) => {
  return (
    <Fragment>
      {text.length > maxLength ? (
        <Fragment>
          <ReactTooltip id={text} />
          <span data-tooltip-id={text} data-tooltip-content={text}>
            <Highlighter
              searchWords={searchTerms}
              autoEscape={true}
              textToHighlight={`${text.substring(0, maxLength - 3)}...`}
            />
          </span>
        </Fragment>
      ) : (
        <Highlighter
          searchWords={searchTerms}
          autoEscape={true}
          textToHighlight={text}
        />
      )}
    </Fragment>
  );
};

const UrlWithLabel = ({ url, label, searchTerms }) => {
  if (url === '') {
    return <Fragment />;
  }

  return (
    <Fragment>
      <span className="text-bold text-xs uppercase text-gray-500 pr-2">
        {' '}
        {label}{' '}
      </span>
      <Highlighter
        searchWords={searchTerms}
        autoEscape={true}
        textToHighlight={url}
      />
      <br />
    </Fragment>
  );
};

const phoneNumberUrl = ({ region, provider, sid }) => {
  const region_id = region == 'us' ? 'us1' : 'ie1';

  if (provider === 'twilio') {
    return `https://www.twilio.com/console/phone-numbers/${sid}?x-target-region=${region_id}`;
  }

  return `https://dt.eu-signalwire.com/phone_numbers/${sid}`;
};

const Numbers = () => {
  const defaultOptions = {
    ...JSON.parse(
      document.getElementById('numbers').dataset.defaultQueryParams
    ),
    refreshing: false,
  };

  const paginationOptions = {
    page: parseInt(getQueryParam('number', 'page')) || defaultOptions.page,
    per_page:
      parseInt(getQueryParam('number', 'per_page')) || defaultOptions.per_page,
  };

  const sortOptions = {
    sort_col: getQueryParam('number', 'sort_col') || defaultOptions.sort_col,
    sort_dir: getQueryParam('number', 'sort_dir') || defaultOptions.sort_dir,
  };

  const filterOptions = {
    id: getQueryParam('number', 'id'),
    phone: getQueryParam('number', 'phone'),
    name: getQueryParam('number', 'name'),
    search_urls: getQueryParam('number', 'search_urls'),
    path: getQueryParam('number', 'path'),
    sid: getQueryParam('number', 'sid'),
    search: getQueryParam('number', 'search'),
  };

  const [options, setOptions] = useState({
    ...paginationOptions,
    ...sortOptions,
    ...filterOptions,
    refreshing: false,
  });

  const changeOptions = useCallback(
    (newParams) => {
      setOptions((options) => ({ ...options, ...newParams }));
    },
    [setOptions]
  );

  const columns = useMemo(() => {
    const globalSearchTerms = !!options.search
      ? options.search?.split(' ')
      : [];
    return [
      {
        accessor: 'name',
        Header: 'Name',
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              column={column}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  name: value,
                  page: 1,
                }));
              }}
              value={options.name}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <Fragment>
              {value && (
                <TooltipWrapper
                  text={value}
                  searchTerms={[...globalSearchTerms, options.name]}
                  maxLength={50}
                />
              )}
              <br />
              <span className="text-gray-400">
                {original.twilio_account_name}
              </span>
            </Fragment>
          );
        },
        width: '10%',
      },
      {
        accessor: 'phone',
        Header: 'Phone',
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              column={column}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  phone: value,
                  page: 1,
                }));
              }}
              value={options.phone}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <Fragment>
              <a className="text-indigo-600" href={`/numbers/${original.id}`}>
                <TooltipWrapper
                  text={value}
                  searchTerms={[...globalSearchTerms, options.phone]}
                />
              </a>
              <br />
              {original.path.length > 0 &&
                createPath(original.path, [...globalSearchTerms])}
            </Fragment>
          );
        },
        width: '10%',
      },
      {
        accessor: 'last_call',
        Header: 'Last Call',
        Filter: () => {
          return <Fragment></Fragment>;
        },
        Cell: ({ value, row }) => {
          const { original } = row;
          const time = original.last_call
            ? moment(original.last_call.created_at).format('HH:mm')
            : null;
          const date = original.last_call
            ? moment(original.last_call.created_at).format('DD MMMM YYYY')
            : null;
          return (
            <>
              <span>{date}</span>
              <br />
              <small className="text-gray-400">{time}</small>
            </>
          );
        },
        disableSortBy: true,
        width: '10%',
      },

      {
        accessor: 'voice_url',
        Header: 'URLs',
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              column={column}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  search_urls: value,
                  page: 1,
                }));
              }}
              value={options.search_urls}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;

          return (
            <Fragment>
              <span className="block mb-1">
                <UrlWithLabel
                  url={original.voice_url}
                  label={'Voice'}
                  searchTerms={[...globalSearchTerms, options.search_urls]}
                />
                <UrlWithLabel
                  url={original.sms_url}
                  label={'SMS'}
                  searchTerms={[...globalSearchTerms, options.search_urls]}
                />
                <UrlWithLabel
                  url={original.status_callback}
                  label={'Status'}
                  searchTerms={[...globalSearchTerms, options.search_urls]}
                />
              </span>
            </Fragment>
          );
        },
        width: '10%',
      },
      {
        accessor: 'sid',
        Header: 'SID',
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              column={column}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  sid: value,
                  page: 1,
                }));
              }}
              value={options.sid}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;
          const provider = original.provider == 'twilio' ? 'Twilio' : 'SignalWire';

          return (
            <a
              href={phoneNumberUrl(original)}
              className="text-indigo-600"
              target="_blank">
              {provider}
            </a>
          );
        },
        width: '10%',
      },
      {
        accessor: 'region',
        Header: 'Region',
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              column={column}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  region: value,
                  page: 1,
                }));
              }}
              value={options.region}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;

          return (
            <Fragment>
              <span className="block mb-1">
                <TooltipWrapper
                  text={original.region}
                  searchTerms={[...globalSearchTerms, options.region]}
                />
              </span>
            </Fragment>
          );
        },
        width: '10%',
      },
      {
        accessor: 'provider',
        Header: 'Provider',
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              column={column}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  provider: value,
                  page: 1,
                }));
              }}
              value={options.provider}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;

          return (
            <Fragment>
              <span className="block mb-1">
                <TooltipWrapper
                  text={original.provider}
                  searchTerms={[...globalSearchTerms, options.provider]}
                />
              </span>
            </Fragment>
          );
        },
        width: '10%',
      },
    ];
  }, [JSON.stringify(options)]);

  const fetchNumbers = async (key, options) => {
    const searchParams = queryParamsUrl('number', options, {});
    const url = new URL(numbersEndpoint);
    url.search = searchParams;
    const response = await fetch(url);
    const { data, meta } = await response.json();
    return { data: data.map((data) => data.attributes), meta };
  };

  useEffect(() => {
    const searchParams = queryParamsUrl('number', options, defaultOptions);
    insertUrlParam(searchParams);
  }, [JSON.stringify(options)]);

  const [refetchInterval, setRefetchInterval] = useState(30000);

  const { isLoading, resolvedData, isFetching } = usePaginatedQuery(
    ['numbers', options],
    fetchNumbers,
    {
      refetchInterval: refetchInterval,
      retry: 1,
    }
  );

  const tools = useMemo(
    () => [
      <GlobalFilter
        onSetGlobalFilter={(value) =>
          setOptions((options) => ({
            ...options,
            search: value,
          }))
        }
        searchValue={options.search}
        key="GlobalFilter"
      />,
      <CleanFiltersButton
        onResetFilters={() => {
          setAll(filterOptions, null);
          setOptions((options) => ({
            ...options,
            ...filterOptions,
          }));
        }}
        key="CleanFiltersButton"
      />,
      <CopyUrlButton key="CopyUrlButton" />,
      <PauseButton
        refetchInterval={refetchInterval}
        onSetRefetchInterval={setRefetchInterval}
        key="PauseButton"
      />,

      <RefreshButton
        isFetching={isFetching}
        key="RefreshButton"
        onRefreshTable={() =>
          setOptions((options) => ({
            ...options,
            refreshing: !options.refreshing,
          }))
        }
      />,
      <DownloadButton
        key="DownloadButton"
        onDownload={() => {
          const { page, per_page, ...rest } = options;
          const {
            page: defaultOptionsPage,
            per_page: defaultOptionsPerPage,
            ...defaultOptionsRest
          } = defaultOptions;
          const searchParams = queryParamsUrl(
            'number',
            rest,
            defaultOptionsRest
          );

          const [currentUrl, queryParams] = window.location.href.split('?');

          location.href = currentUrl + '.csv?' + searchParams;
        }}
      />,
    ],
    [options.refreshing, options.search, isFetching, refetchInterval]
  );

  return !isLoading ? (
    <>
      <DataTable
        data={resolvedData.data}
        columns={columns}
        setOptions={changeOptions}
        pageCount={resolvedData.meta.pages.total}
        sortOptions={sortOptions}
        paginationOptions={paginationOptions}
        fetching={isFetching}
        filterOptions={filterOptions}
        tools={tools}
      />
      <ToastContainer />
    </>
  ) : (
    <div className="box align-middle min-w-full">
      <TableLoader />
    </div>
  );
};

export default Numbers;
