import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import {usePaginatedQuery} from 'react-query';
import DataTable from '../components/datatable';
import CopyUrlButton from '../components/CopyUrlButton';
import RefreshButton from '../components/RefreshButton';
import PauseButton from '../components/PauseButton';
import CleanFiltersButton from '../components/CleanFiltersButton';
import {
  TextColumnFilter,
  DateTimeColumnFilter,
  GlobalFilter,
} from '../components/filters';
import {textMessagesEndpoint} from '../endpoints';
import moment from 'moment';
import TableLoader from '../components/datatable/TableLoader';
import Highlighter from 'react-highlight-words';
import {
  insertUrlParam,
  queryParamsUrl,
  getQueryParam,
} from '../utils/queryParamsHelper';
import 'react-toastify/dist/ReactToastify.css';
import {setAll} from '../utils/objHelper';
import TwilioAlertsModal from '../components/modal';

const TextMessages = () => {
  const [showModal, setShowModal] = useState(false);
  const [twilioAlerts, setTwilioAlerts] = useState([]);

  const defaultOptions = {
    ...JSON.parse(
      document.getElementById('text_messages').dataset.defaultQueryParams
    ),
    refreshing: false,
  };

  const paginationOptions = {
    page:
      parseInt(getQueryParam('text_message', 'page')) || defaultOptions.page,
    per_page:
      parseInt(getQueryParam('text_message', 'per_page')) ||
      defaultOptions.per_page,
  };

  const sortOptions = {
    sort_col:
      getQueryParam('text_message', 'sort_col') || defaultOptions.sort_col,
    sort_dir:
      getQueryParam('text_message', 'sort_dir') || defaultOptions.sort_dir,
  };

  const filterOptions = {
    phone: getQueryParam('text_message', 'phone'),
    sender: getQueryParam('text_message', 'sender'),
    created_at_from: getQueryParam('text_message', 'created_at_from'),
    created_at_to: getQueryParam('text_message', 'created_at_to'),
    body: getQueryParam('text_message', 'body'),
    id: getQueryParam('text_message', 'id'),
    search: getQueryParam('text_message', 'search'),
  };

  const [options, setOptions] = useState({
    ...paginationOptions,
    ...sortOptions,
    ...filterOptions,
    refreshing: false,
  });

  const changeOptions = useCallback(
    (newParams) => {
      setOptions((options) => ({...options, ...newParams}));
    },
    [setOptions]
  );

  const columns = useMemo(() => {
    const globalSearchTerms = !!options.search
      ? options.search?.split(' ')
      : [];

    return [
      {
        accessor: 'created_at',
        Header: 'Sent At',
        Filter: ({column}) => {
          return (
            <DateTimeColumnFilter
              column={column}
              fromDate={options.created_at_from}
              toDate={options.created_at_to}
              onChange={(values) => {
                setOptions((options) => ({
                  ...options,
                  created_at_from: values.startDate,
                  created_at_to: values.endDate,
                  page: 1,
                }));
              }}
            />
          );
        },
        Cell: ({value}) => {
          const time = moment(value).format('HH:mm');
          const date = moment(value).format('DD MMMM YYYY');
          return (
            <>
              <span>{time}</span>
              <br />
              <span className="text-gray-400">{date}</span>
            </>
          );
        },
        width: '12.5%',
      },

      {
        accessor: 'sender',
        Header: 'From',
        Filter: ({column}) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({...options, sender: value, page: 1}));
            }}
            value={options.sender}
          />
        ),
        Cell: ({value}) => {
          return (
            value && (
              <Highlighter
                searchWords={[options.sender, ...globalSearchTerms]}
                autoEscape={true}
                textToHighlight={value}
              />
            )
          );
        },
        width: '15.5%',
      },
      {
        accessor: 'phone',
        Header: 'To',
        Filter: ({column}) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({...options, phone: value, page: 1}));
            }}
            value={options.phone}
          />
        ),
        Cell: ({value, row: {original}}) => {
          return (
            <Fragment>
              <Highlighter
                searchWords={[options.phone, ...globalSearchTerms]}
                autoEscape={true}
                textToHighlight={value}
              />
              {original.twilio_alerts.length > 0 && (
                <span
                  onClick={() => {
                    setShowModal(true);
                    setTwilioAlerts(original.twilio_alerts);
                  }}
                  className={`ml-2 px-3 py-1 rounded-full text-xs font-medium bg-red-200 text-red-500 cursor-pointer`}>
                  !
                </span>
              )}
              <br />
              <span className={"text-gray-400 capitalize text-xs"}>{original.provider}</span>
            </Fragment>
          );
        },
        width: '15.5%',
      },
      {
        accessor: 'body',
        Header: 'Body',
        Filter: ({column}) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({...options, body: value, page: 1}));
            }}
            value={options.body}
          />
        ),
        Cell: ({value}) => {
          return (
            <div>
              <Highlighter
                searchWords={[options.body, ...globalSearchTerms]}
                autoEscape={true}
                textToHighlight={value}
              />
            </div>
          );
        },
        width: '30.5%',
      },
    ];
  }, [JSON.stringify(options)]);

  const fetchTextMessages = async (key, options) => {
    const searchParams = queryParamsUrl('text_message', options, {});
    const url = new URL(textMessagesEndpoint);
    url.search = searchParams;
    const response = await fetch(url);
    const {data, meta} = await response.json();
    return {data: data.map((data) => data.attributes), meta};
  };

  useEffect(() => {
    const searchParams = queryParamsUrl('waiter', options, defaultOptions);
    insertUrlParam(searchParams);
  }, [JSON.stringify(options)]);

  const [refetchInterval, setRefetchInterval] = useState(10000);

  const {isLoading, resolvedData, isFetching} = usePaginatedQuery(
    ['text_messages', options],
    fetchTextMessages,
    {
      refetchInterval: refetchInterval,
      retry: 1,
    }
  );

  const tools = useMemo(
    () => [
      <GlobalFilter
        onSetGlobalFilter={(value) =>
          setOptions((options) => ({
            ...options,
            search: value,
          }))
        }
        searchValue={options.search}
        key="GlobalFilter"
      />,
      <CleanFiltersButton
        onResetFilters={() => {
          setAll(filterOptions, null);
          setOptions((options) => ({
            ...options,
            ...filterOptions,
          }));
        }}
        key="CleanFiltersButton"
      />,
      <CopyUrlButton key="CopyUrlButton" />,
      <PauseButton
        refetchInterval={refetchInterval}
        onSetRefetchInterval={setRefetchInterval}
        key="PauseButton"
      />,

      <RefreshButton
        isFetching={isFetching}
        key="RefreshButton"
        onRefreshTable={() =>
          setOptions((options) => ({
            ...options,
            refreshing: !options.refreshing,
          }))
        }
      />,
    ],
    [options.refreshing, options.search, isFetching, refetchInterval]
  );

  return !isLoading ? (
    <>
      <TwilioAlertsModal
        showModal={showModal}
        setShowModal={setShowModal}
        twilioAlerts={twilioAlerts}
      />
      <DataTable
        data={resolvedData.data}
        columns={columns}
        tools={tools}
        setOptions={changeOptions}
        pageCount={resolvedData.meta.pages.total}
        sortOptions={sortOptions}
        paginationOptions={paginationOptions}
        filterOptions={filterOptions}
        fetching={isFetching}
      />
    </>
  ) : (
    <div className="box align-middle min-w-full">
      <TableLoader />
    </div>
  );
};

export default TextMessages;
