require('@rails/ujs').start();
require('@rails/activestorage').start();

import './graphs';
import './bot';
import './call-flows';
import './dashboard';
import './nav';
import './line-groups';
import './waiters';
import './calls';
import './numbers';
import './text-messages';
import './forwarded-calls';
