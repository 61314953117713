import React, {Fragment, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTable, useFilters, useSortBy, usePagination} from 'react-table';
import {DefaultColumnFilter, GlobalFilter} from '../filters';
import Toolbar from '../toolbar';
import {motion, AnimatePresence} from 'framer-motion';

const DataTableSortBy = ({column}) =>
  column.isSorted ? (
    column.isSortedDesc ? (
      <svg
        className="h-4 w-4 mx-2 mt-1 inline-block"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 612.02 612.02"
        fill="currentColor"
        space="preserve">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
        />
      </svg>
    ) : (
      <svg
        className="h-4 w-4 mx-2  mt-1 inline-block"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 612.02 612.02"
        fill="currentColor"
        space="preserve">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M248.292,106.406l194.281,194.29c12.365,12.359,12.365,32.391,0,44.744c-12.354,12.354-32.391,12.354-44.744,0   L225.923,173.529L54.018,345.44c-12.36,12.354-32.395,12.354-44.748,0c-12.359-12.354-12.359-32.391,0-44.75L203.554,106.4   c6.18-6.174,14.271-9.259,22.369-9.259C234.018,97.141,242.115,100.232,248.292,106.406z"
        />
      </svg>
    )
  ) : (
    ''
  );

DataTableSortBy.propTypes = {
  column: PropTypes.object.isRequired,
};

const DataTableHeader = ({headerGroups}) =>
  headerGroups.map((headerGroup, index) => (
    <Fragment key={index}>
      <thead {...headerGroup.getHeaderGroupProps()}>
        <tr>
          {headerGroup.headers.map((column) => {
            return (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className="select-none">
                <span>
                  {column.render('Header')}

                  <DataTableSortBy column={column} />
                </span>
              </th>
            );
          })}
        </tr>
      </thead>
      <thead>
        <tr>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps()}>
              {column.canFilter ? column.render('Filter') : null}
            </th>
          ))}
        </tr>
      </thead>
    </Fragment>
  ));

DataTableHeader.propTypes = {
  headerGroups: PropTypes.array.isRequired,
};

const DataTableBody = ({page, getTableBodyProps, prepareRow}) => (
  <tbody {...getTableBodyProps()}>
    {page.map((row, i) => {
      const variants = {
        initial: {
          opacity: 0,
        },
        animate: (i) => ({
          opacity: 1,
          transition: {duration: 0.8},
        }),
      };

      prepareRow(row);
      return (
        <motion.tr
          initial="initial"
          animate="animate"
          variants={variants}
          custom={i}
          {...row.getRowProps()}
          key={row.original.id}
          className={i % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
          {row.cells.map((cell) => {
            return (
              <td
                className={cell.column.Header}
                {...cell.getCellProps()}
                style={{width: cell.column.width}}>
                {cell.render('Cell')}
              </td>
            );
          })}
        </motion.tr>
      );
    })}
  </tbody>
);

DataTableBody.propTypes = {
  page: PropTypes.array.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
};

const DataTablePagination = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  nextPage,
  previousPage,
  setPageSize,
  gotoPage,
  pageIndex,
  pageSize,
}) => {
  return (
    <div className="px-3 py-2 flex items-baseline justify-between bg-white rounded-b-lg text-sm">
      <span className="text-gray-500">
        Page <strong>{pageIndex + 1}</strong> of{' '}
        <strong>{pageOptions.length}</strong>
      </span>

      <div className="flex items-baseline justify-between">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className="form-input block focus:outline-none focus:ring focus:ring-yellow focus:border-yellow-300 pl-7 pr-12 sm:text-sm sm:leading-5 mx-6">
          {[10, 20, 30, 40, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>

        <nav className="flex block justify-end">
          <ul className="flex pl-0 rounded list-none flex-wrap">
            <li>
              <a
                className={`cursor-pointer first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-yellow-300 bg-white text-yellow-400 hover:ring hover:ring-yellow ${
                  !canPreviousPage &&
                  'opacity-25 border-transparent text-white cursor-not-allowed pointer-events-none'
                }`}
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}>
                <svg
                  className="h-3 w-3"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 612.02 612.02"
                  fill="currentColor"
                  space="preserve">
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M124.172,305.975L342.365,87.781c20.079-20.079,20.079-52.644,0-72.722c-20.079-20.079-52.644-20.079-72.723,0     L15.062,269.639c-20.079,20.079-20.079,52.644,0,72.723l254.58,254.58c20.079,20.078,52.644,20.078,72.723,0     c20.079-20.079,20.079-52.644,0-72.723L124.172,305.975z M431.395,305.694l165.371-165.982c20.308-20.359,20.308-53.408,0-73.768     c-20.309-20.359-53.204-20.359-73.513,0L321.139,268.823c-20.309,20.359-17.047,35.266,3.032,55.345L523.253,545.47     c20.309,20.359,53.204,20.359,73.513,0c20.308-20.359,20.308-53.408,0-73.768L431.395,305.694z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                className={`cursor-pointer first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-yellow-300 bg-white text-yellow-400 hover:ring hover:ring-yellow ${
                  !canPreviousPage &&
                  'opacity-25 border-transparent text-white cursor-not-allowed pointer-events-none'
                }`}
                onClick={() => previousPage()}
                disabled={!canPreviousPage}>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                className={`cursor-pointer first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-yellow-300 bg-white text-yellow-400 hover:ring hover:ring-yellow ${
                  !canNextPage &&
                  'opacity-25 border-transparent text-white cursor-not-allowed pointer-events-none'
                }`}
                onClick={() => nextPage()}
                disabled={!canNextPage}>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a
                className={`cursor-pointer first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-yellow-300 bg-white text-yellow-400 hover:ring hover:ring-yellow ${
                  !canNextPage &&
                  'opacity-25 border-transparent text-white cursor-not-allowed pointer-events-none'
                }`}
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}>
                <svg
                  className="h-3 w-3"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 612.02 612.02"
                  fill="currentColor"
                  space="preserve">
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M596.96,269.674L342.381,15.094c-20.079-20.079-52.644-20.079-72.723,0c-20.079,20.079-20.079,52.644,0,72.723     L487.852,306.01L269.658,524.202c-20.079,20.079-20.079,52.644,0,72.723s52.644,20.079,72.723,0L596.96,342.346     C617.039,322.317,617.039,289.753,596.96,269.674z M290.858,254.258L88.744,41.238c-20.309-21.378-53.204-21.378-73.513,0     s-20.309,56.058,0,77.462l165.371,174.289L15.231,467.278c-20.309,21.379-20.309,56.083,0,77.462s53.204,21.379,73.513,0     L290.858,331.72C311.167,310.342,311.167,275.662,290.858,254.258z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

DataTablePagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.array.isRequired,
  pageCount: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

const DataTable = ({
  columns,
  data,
  pageCount: controlledPageCount,
  setOptions,
  fetching,
  sortOptions,
  paginationOptions,
  filterOptions,
  tools,
}) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state: {pageIndex, pageSize, sortBy},
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: paginationOptions.page - 1,
        pageSize: paginationOptions.per_page,
        sortBy: [
          {id: sortOptions.sort_col, desc: sortOptions.sort_dir === 'desc'},
        ],
      },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
    },

    useFilters,
    useSortBy,
    usePagination
  );

  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageIndex,
    pageSize,
  };

  const bodyProps = {
    getTableBodyProps,
    page,
    prepareRow,
  };

  useEffect(() => {
    const sortOptions =
      sortBy.length > 0
        ? {
            sort_col: sortBy[0].id,
            sort_dir: sortBy[0].desc ? 'desc' : 'asc',
          }
        : {};

    setOptions({
      page: pageIndex + 1,
      per_page: pageSize,
      ...sortOptions,
    });
  }, [setOptions, pageIndex, pageSize, sortBy]);

  return (
    <Fragment>
      <div className="min-w-full flex justify-between mb-4 h-13">
        <Toolbar>{tools}</Toolbar>
      </div>

      <div className="box align-middle min-w-full">
        <table {...getTableProps()} className="min-w-full">
          <DataTableHeader
            headerGroups={headerGroups}
            recordCount={page.length}
            searchRecords={(value) => setOptions({search: value})}
            searchValue={filterOptions.search}
          />
          <DataTableBody {...bodyProps} />
        </table>
        <DataTablePagination {...paginationProps} />
      </div>
    </Fragment>
  );
};

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      Header: PropTypes.string.isRequired,
      Filter: PropTypes.any,
      filter: PropTypes.any,
    })
  ),
  setOptions: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  sortOptions: PropTypes.object.isRequired,
  paginationOptions: PropTypes.object.isRequired,
  filterOptions: PropTypes.object.isRequired,
  tools: PropTypes.arrayOf(PropTypes.element),
};

export default DataTable;
