import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import { usePaginatedQuery, useQuery } from 'react-query';
import DataTable from '../components/datatable';
import CopyUrlButton from '../components/CopyUrlButton';
import RefreshButton from '../components/RefreshButton';
import PauseButton from '../components/PauseButton';
import CleanFiltersButton from '../components/CleanFiltersButton';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateTimeColumnFilter,
  GlobalFilter,
  TimeColumnFilter,
} from '../components/filters';
import { forwardedCallsEndpoint, linesEndpoint } from '../endpoints';
import moment from 'moment';
import TableLoader from '../components/datatable/TableLoader';
import Highlighter from 'react-highlight-words';
import {
  insertUrlParam,
  queryParamsUrl,
  getQueryParam,
} from '../utils/queryParamsHelper';
import createPath from '../utils/pathHelper';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { setAll } from '../utils/objHelper';
import TwilioAlertsModal from '../components/modal';

const ForwardedCalls = () => {
  const [showModal, setShowModal] = useState(false);
  const [twilioAlerts, setTwilioAlerts] = useState([]);

  const defaultOptions = {
    ...JSON.parse(
      document.getElementById('forwarded_calls').dataset.defaultQueryParams
    ),
    refreshing: false,
  };

  const paginationOptions = {
    page:
      parseInt(getQueryParam('forwarded_call', 'page')) || defaultOptions.page,
    per_page:
      parseInt(getQueryParam('forwarded_call', 'per_page')) ||
      defaultOptions.per_page,
  };

  const sortOptions = {
    sort_col:
      getQueryParam('forwarded_call', 'sort_col') || defaultOptions.sort_col,
    sort_dir:
      getQueryParam('forwarded_call', 'sort_dir') || defaultOptions.sort_dir,
  };

  const filterOptions = {
    phone: getQueryParam('forwarded_call', 'phone'),
    status: getQueryParam('forwarded_call', 'status'),
    waiter_id: getQueryParam('forwarded_call', 'waiter_id'),
    call_type: getQueryParam('forwarded_call', 'call_type'),
    created_at_from:
      getQueryParam('forwarded_call', 'created_at_from') ||
      defaultOptions.created_at_from,
    created_at_to: getQueryParam('forwarded_call', 'created_at_to'),
    id: getQueryParam('forwarded_call', 'id'),
    line_id: getQueryParam('forwarded_call', 'line_id'),
    search: getQueryParam('forwarded_call', 'search'),
    region: getQueryParam('forwarded_call', 'region'),
    provider: getQueryParam('forwarded_call', 'provider'),
  };

  const [options, setOptions] = useState({
    ...paginationOptions,
    ...sortOptions,
    ...filterOptions,
    refreshing: false,
  });

  const changeOptions = useCallback(
    (newParams) => {
      setOptions((options) => ({ ...options, ...newParams }));
    },
    [setOptions]
  );

  const fetchLines = async (key, options) => {
    let endpoint = linesEndpoint;

    const url = new URL(endpoint);
    const response = await fetch(url);
    const { data } = await response.json();
    return data.map(({ attributes }) => ({
      value: attributes.id,
      label: `${attributes.id} ${attributes.name}`,
    }));
  };

  const { data: lines, status: linesStatus } = useQuery(
    ['lines', {}],
    fetchLines,
    {
      retry: 1,
    }
  );

  const callUrl = ({ region, provider, call_sid }) => {
    const region_id = region == 'us' ? 'us1' : 'ie1';

    if (provider.toLowerCase() === 'twilio') {
      return `https://www.twilio.com/console/voice/calls/logs/${call_sid}?x-target-region=${region_id}`;
    }

    return `https://dt.eu-signalwire.com/laml-calls/${call_sid}`;
  };

  const columns = useMemo(() => {
    const globalSearchTerms = !!options.search
      ? options.search?.split(' ')
      : [];

    return [
      {
        accessor: 'phone',
        Header: 'Phone',
        Filter: ({ column }) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({ ...options, phone: value, page: 1 }));
            }}
            value={options.phone}
          />
        ),
        Cell: ({ value, row }) => {
          return (
            <>
              <a
                className="text-indigo-700 text-bold"
                href={`/forwarded_calls/${row.original.id}`}>
                <Highlighter
                  searchWords={[options.phone, ...globalSearchTerms]}
                  autoEscape={true}
                  textToHighlight={value}
                />
                <br />
                <span className="text-gray-400">
                  <Highlighter
                    searchWords={[...globalSearchTerms]}
                    autoEscape={true}
                    textToHighlight={row.original.name}
                  />
                </span>
              </a>
            </>
          );
        },
        width: '15.5%',
      },
      {
        accessor: 'created_at',
        Header: 'Called At',
        Filter: ({ column }) => {
          return (
            <DateTimeColumnFilter
              column={column}
              fromDate={options.created_at_from}
              toDate={options.created_at_to}
              onChange={(values) => {
                setOptions((options) => ({
                  ...options,
                  created_at_from: values.startDate,
                  created_at_to: values.endDate,
                  page: 1,
                }));
              }}
            />
          );
        },
        Cell: ({ value }) => {
          const time = moment(value).format('HH:mm');
          const date = moment(value).format('DD MMMM YYYY');
          return (
            <>
              <span>{time}</span>
              <br />
              <span className="text-gray-400">{date}</span>
            </>
          );
        },
        width: '12.5%',
      },
      {
        accessor: 'duration',
        Header: 'Duration',
        Filter: ({ column }) => {
          const greaterThan = moment()
            .startOf('day')
            .seconds(options.duration_gt);
          const lessThan = moment().startOf('day').seconds(options.duration_lt);
          return (
            <TimeColumnFilter
              column={column}
              greaterThan={greaterThan}
              lessThan={lessThan}
              onChange={(values) => {
                setOptions((options) => ({
                  ...options,
                  ...values,
                  page: 1,
                }));
              }}
            />
          );
        },
        Cell: ({ value, row }) => {
          return (
            <a className="text-bold">
              {value
                ? moment().startOf('day').seconds(value).format('mm:ss')
                : '-'}
            </a>
          );
        },
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'status',
        Header: 'Status',
        Filter: ({ column }) => {
          const statusOptions = [
            { value: null, label: 'All' },
            { value: 'queued', label: 'Queued' },
            { value: 'ringing', label: 'Ringing' },
            { value: 'in-progress', label: 'In Progress' },
            { value: 'busy', label: 'Busy' },
            { value: 'failed', label: 'Failed' },
            { value: 'completed', label: 'Completed' },
            { value: 'no-answer', label: 'No Answer' },
            { value: 'canceled', label: 'Canceled' },
          ];

          const selectedOption = statusOptions.find(
            (option) => option.value === options.status
          ) || {
            value: null,
            label: 'All',
          };

          return (
            <SelectColumnFilter
              column={column}
              options={statusOptions}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  status: value,
                  page: 1,
                }));
              }}
              value={selectedOption}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;
          const generateTagColor = (value) => {
            switch (value) {
              case 'no-answer':
              case 'failed':
              case 'busy':
              case 'canceled':
                return 'red';
              case 'completed':
                return 'green';
              case 'ringing':
              case 'in-progress':
                return 'blue';
              case 'queued':
                return 'yellow';
            }
          };

          return (
            <div>
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-${generateTagColor(
                  value
                )}-100 text-${generateTagColor(value)}-800`}>
                <Highlighter
                  searchWords={[...globalSearchTerms]}
                  autoEscape={true}
                  textToHighlight={value?.replace('-', ' ')?.toUpperCase()}
                />
              </span>
              {original.twilio_alerts.length > 0 && (
                <span
                  onClick={() => {
                    setShowModal(true);
                    setTwilioAlerts(original.twilio_alerts);
                  }}
                  className={`inline-flex items-center px-3 py-1 rounded-full text-lg font-medium leading-4 bg-red-200 text-white ml-2 cursor-pointer`}>
                  !
                </span>
              )}
            </div>
          );
        },
        width: '10%',
      },
      {
        accessor: 'waiter_id',
        Header: 'Waiter',
        Filter: ({ column }) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({
                ...options,
                waiter_id: value,
                page: 1,
              }));
            }}
            value={options.waiter_id}
          />
        ),
        Cell: ({ value, row }) => {
          const { original } = row;

          return (
            <div className="text-indigo-700">
              <a
                className="text-indigo-700 text-bold"
                href={`/waiters/${original.waiter_id}`}>
                <Highlighter
                  searchWords={[options.phone, ...globalSearchTerms]}
                  autoEscape={true}
                  textToHighlight={value && 'W' + value}
                />
              </a>
            </div>
          );
        },
        width: '10%',
      },
      {
        accessor: 'line_id',
        Header: 'Line',
        Filter: ({ column }) => {
          const lineOptions = [{ value: null, label: 'All' }, ...lines] || [];

          const selectedOption = lineOptions.find(
            (option) => option.value === parseInt(options.line_id)
          ) || {
            value: null,
            label: 'All',
          };

          return (
            linesStatus === 'success' && (
              <SelectColumnFilter
                column={column}
                options={lineOptions}
                onChange={(value) =>
                  setOptions((options) => ({
                    ...options,
                    line_id: value,
                    page: 1,
                  }))
                }
                menuWidth={400}
                value={selectedOption}
                isClearable={false}
              />
            )
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <Fragment>
              {createPath(original.path, [...globalSearchTerms])}
            </Fragment>
          );
        },
        disableSortBy: true,
        width: '12.5%',
      },
      {
        accessor: 'call_type',
        Header: 'Type',
        Filter: ({ column }) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({
                ...options,
                call_type: value,
                page: 1,
              }));
            }}
            value={options.call_type}
          />
        ),
        Cell: ({ value, row }) => {
          const { original } = row;

          const typeText = original.call_type.split('_').join(' ');

          return (
            <div className="text-grey-700">
              <Highlighter
                searchWords={[options.call_type, ...globalSearchTerms]}
                autoEscape={true}
                textToHighlight={
                  typeText.charAt(0).toUpperCase() + typeText.slice(1)
                }
              />
            </div>
          );
        },
        width: '10%',
      },
      {
        accessor: 'call_sid',
        Header: 'SID',
        Filter: ({ column }) => (
          <TextColumnFilter
            column={column}
            onChange={(value) =>
              setOptions((options) => ({ ...options, call_sid: value, page: 1 }))
            }
            value={options.call_sid}
          />
        ),
        Cell: ({ value, row }) => {
          const { original } = row;
          const sidLink = callUrl(original);

          return (
            <>
              <span className="block mb-1 font-semibold">
                {original.leg &&
                  original.leg.charAt(0).toUpperCase() +
                  original.leg.slice(1).split('_').join(' ')}
              </span>
              <small className="flex flex-row">
                <a
                  className="text-indigo-700 text-bold"
                  target="_blank"
                  href={sidLink}
                  title={value}>
                  <Highlighter
                    searchWords={[...globalSearchTerms]}
                    autoEscape={true}
                    textToHighlight={value}
                  />
                </a>
                <CopyToClipboard
                  text={value}
                  onCopy={() =>
                    toast.success('Copied to clipboard!', {
                      position: 'top-right',
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    })
                  }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="w-3 h-3 ml-1 mt-1 cursor-pointer">
                    <path
                      fill="#5145cd"
                      d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path>
                  </svg>
                </CopyToClipboard>
              </small>
            </>
          );
        },
        width: '10%',
      },

      {
        accessor: 'region',
        Header: 'Region',
        Filter: ({ column }) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({ ...options, region: value, page: 1 }));
            }}
            value={options.region}
          />
        ),
        Cell: ({ value, row }) => {
          return (
            <div className="text-grey-700">
              <Highlighter
                searchWords={[options.region, ...globalSearchTerms]}
                autoEscape={true}
                textToHighlight={
                  value
                }
              />
            </div>
          );
        },
        width: '15.5%',
      },

      {
        accessor: 'provider',
        Header: 'Provider',
        Filter: ({ column }) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({ ...options, provider: value, page: 1 }));
            }}
            value={options.provider}
          />
        ),
        Cell: ({ value, row }) => {
          return (
            <div className="text-grey-700">
              <Highlighter
                searchWords={[options.provider, ...globalSearchTerms]}
                autoEscape={true}
                textToHighlight={
                  value
                }
              />
            </div>
          );
        },
        width: '15.5%',
      },
    ];
  }, [linesStatus, JSON.stringify(options)]);

  const fetchForwardedCalls = async (key, options) => {
    const searchParams = queryParamsUrl('forwarded_call', options, {});
    const url = new URL(forwardedCallsEndpoint);
    url.search = searchParams;
    const response = await fetch(url);
    const { data, meta } = await response.json();
    return { data: data.map((data) => data.attributes), meta };
  };

  useEffect(() => {
    const searchParams = queryParamsUrl(
      'forwarded_call',
      options,
      defaultOptions
    );
    insertUrlParam(searchParams);
  }, [JSON.stringify(options)]);

  const [refetchInterval, setRefetchInterval] = useState(10000);

  const { isLoading, resolvedData, isFetching } = usePaginatedQuery(
    ['forwarded_calls', options],
    fetchForwardedCalls,
    {
      refetchInterval: refetchInterval,
      retry: 1,
    }
  );

  const tools = useMemo(
    () => [
      <GlobalFilter
        onSetGlobalFilter={(value) =>
          setOptions((options) => ({
            ...options,
            search: value,
          }))
        }
        searchValue={options.search}
        key="GlobalFilter"
      />,
      <CleanFiltersButton
        onResetFilters={() => {
          setAll(filterOptions, null);
          setOptions((options) => ({
            ...options,
            ...filterOptions,
          }));
        }}
        key="CleanFiltersButton"
      />,
      <CopyUrlButton key="CopyUrlButton" />,
      <PauseButton
        refetchInterval={refetchInterval}
        onSetRefetchInterval={setRefetchInterval}
        key="PauseButton"
      />,

      <RefreshButton
        isFetching={isFetching}
        key="RefreshButton"
        onRefreshTable={() =>
          setOptions((options) => ({
            ...options,
            refreshing: !options.refreshing,
          }))
        }
      />,
    ],
    [options.refreshing, options.search, isFetching, refetchInterval]
  );

  return !isLoading && linesStatus !== 'loading' ? (
    <>
      <TwilioAlertsModal
        setShowModal={setShowModal}
        twilioAlerts={twilioAlerts}
        showModal={showModal}
      />

      <DataTable
        data={resolvedData.data}
        columns={columns}
        tools={tools}
        setOptions={changeOptions}
        pageCount={resolvedData.meta.pages.total}
        sortOptions={sortOptions}
        paginationOptions={paginationOptions}
        filterOptions={filterOptions}
        fetching={isFetching}
      />
    </>
  ) : (
    <div className="box align-middle min-w-full">
      <TableLoader />
    </div>
  );
};

export default ForwardedCalls;
