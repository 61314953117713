import React from 'react';
import {Group} from '@visx/group';
import {Cluster, Tree} from '@visx/hierarchy';
import {LinkVertical} from '@visx/shape';
import {hierarchy} from 'd3-hierarchy';

const peach = '#fd9b93';
const pink = '#fe6e9e';
const blue = '#03c0dc';
const green = '#26deb0';
const plum = '#71248e';
const lightpurple = '#B794F4';
const white = '#ffffff';
const citrus = '#ddf163';
const vqyellow = '#f5d05e';
const aqua = '#37ac8c';
const merlinsbeard = '#f7f7f3';
const bg = '#272b4d';
const gray = '#333333';
const lightgray = '#999999';
const lightergray = '#aaaaaa';
const vlightgray = '#cccccc';

function strokeColor({children, data}, showCall) {
  if (showCall && !data.visited) {
    return vlightgray;
  }

  if (data.type == 'External') {
    return lightgray;
  }

  if (data.node_name == 'line_up' || data.node_name == 'line_up_and_confirm') {
    return vqyellow;
  }

  return !!children ? green : lightpurple;
}

function fillColor({children, data}, showCall) {
  if (showCall && !data.visited) {
    return vlightgray;
  }

  if (data.type == 'External') {
    return white;
  }

  if (data.node_name == 'line_up' || data.node_name == 'line_up_and_confirm') {
    return vqyellow;
  }

  return !!children ? green : white;
}

function Node({node, showCall}) {
  const isRoot = node.depth === 0;
  if (isRoot) return <RootNode node={node} />;

  return (
    <Group top={node.y} left={node.x}>
      {node.depth !== 0 && (
        <circle
          r={node.data.node_name == 'line_up' ? 6 : 5}
          fill={fillColor(node, showCall)}
          stroke={strokeColor(node, showCall)}
          strokeWidth={'2px'}
        />
      )}
      <text
        dx={'8px'}
        dy={'.33em'}
        fontSize={15}
        fontFamily="Inter, system-ui, -apple-system, Roboto"
        textAnchor={'left'}
        style={{pointerEvents: 'none'}}
        fill={showCall && !node.data.visited ? lightgray : gray}>
        {node.data.name}
      </text>
      {node.data.subtext && (
        <text
          dx={'8px'}
          dy={'1.7em'}
          fontSize={10}
          fontFamily="Inter, system-ui, -apple-system, Roboto"
          textAnchor={'left'}
          style={{pointerEvents: 'none'}}
          fill={showCall && !node.data.visited ? vlightgray : lightgray}>
          {node.data.subtext}
        </text>
      )}
    </Group>
  );
}

function linkStrokeColor({source, target}, showCall) {
  if (showCall && (!source.data.visited || !target.data.visited)) {
    return vlightgray;
  }
  const sourceType = source.data.type;
  const targetType = target.data.type;
  if (
    (sourceType == 'Redirect' && targetType == 'External') ||
    sourceType == 'External'
  ) {
    return lightgray;
  }
  return lightpurple;
}

function linkStrokeDash({source, target}) {
  const sourceType = source.data.type;
  const targetType = target.data.type;
  if (
    (sourceType == 'Redirect' && targetType == 'External') ||
    sourceType == 'External'
  ) {
    return '4,4';
  }
}

function RootNode({node}) {
  const width = 12;
  const height = 12;
  const centerX = -width / 2;
  const centerY = -height / 2;

  return (
    <Group top={node.y} left={node.x}>
      <rect
        width={width}
        height={height}
        y={centerY}
        x={centerX}
        fill={green}
      />
      <text
        dx={'.5em'}
        dy={'.33em'}
        fontSize={15}
        fontFamily="Inter, system-ui, -apple-system, Roboto"
        textAnchor={'left'}
        style={{pointerEvents: 'none'}}
        fill={gray}>
        {node.data.name}
      </text>
    </Group>
  );
}

export const Graph = ({
  width,
  height,
  tree,
  hierarchyType,
  showCall,
  margin = {
    top: 20,
    left: 30,
    right: 50,
    bottom: 50,
  },
}) => {
  const data = hierarchy(tree);
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const Hierarchy = hierarchyType == 'cluster' ? Cluster : Tree;

  return (
    <svg width={width} height={height}>
      <Hierarchy root={data} size={[xMax, yMax]}>
        {(cluster) => {
          return (
            <Group top={margin.top} left={margin.left}>
              {cluster.links().map((link, i) => {
                return (
                  <LinkVertical
                    key={`cluster-link-${i}`}
                    data={link}
                    stroke={linkStrokeColor(link, showCall)}
                    strokeDasharray={linkStrokeDash(link)}
                    strokeWidth="2"
                    fill="none"
                  />
                );
              })}
              {cluster.descendants().map((node, i) => {
                return (
                  <Node
                    key={`cluster-node-${i}`}
                    node={node}
                    showCall={showCall}
                  />
                );
              })}
            </Group>
          );
        }}
      </Hierarchy>
    </svg>
  );
};
