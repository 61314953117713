import React from 'react';

const Toolbar = ({children}) => {
  return (
    <div className="shadow align-middle p-3 rounded-md  flex items-center bg-gray-100">
      {children.map((tool, index) => (
        <div
          className={index === children.length - 1 ? '' : 'mr-2'}
          key={index}>
          {tool}
        </div>
      ))}
    </div>
  );
};

export default Toolbar;
