import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import { usePaginatedQuery, useQuery } from 'react-query';
import DataTable from '../components/datatable';
import CopyUrlButton from '../components/CopyUrlButton';
import RefreshButton from '../components/RefreshButton';
import PauseButton from '../components/PauseButton';
import CleanFiltersButton from '../components/CleanFiltersButton';
import {
  TextColumnFilter,
  SelectColumnFilter,
  DateTimeColumnFilter,
  GlobalFilter,
} from '../components/filters';
import { waitersEndpoint, linesEndpoint } from '../endpoints';
import moment from 'moment';
import TableLoader from '../components/datatable/TableLoader';
import Highlighter from 'react-highlight-words';
import {
  insertUrlParam,
  queryParamsUrl,
  getQueryParam,
} from '../utils/queryParamsHelper';
import createPath from '../utils/pathHelper';
import 'react-toastify/dist/ReactToastify.css';
import { setAll } from '../utils/objHelper';

const Waiters = ({ lineId, lineGroupId }) => {
  const defaultOptions = {
    ...JSON.parse(
      document.getElementById('waiters').dataset.defaultQueryParams
    ),
    created_at_from: !!window.location.search
      ? null
      : new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // remove the default created at from filter if the user has already chosen query params
    refreshing: false,
  };

  const paginationOptions = {
    page: parseInt(getQueryParam('waiter', 'page')) || defaultOptions.page,
    per_page:
      parseInt(getQueryParam('waiter', 'per_page')) || defaultOptions.per_page,
  };

  const sortOptions = {
    sort_col: getQueryParam('waiter', 'sort_col') || defaultOptions.sort_col,
    sort_dir: getQueryParam('waiter', 'sort_dir') || defaultOptions.sort_dir,
  };

  const filterOptions = {
    phone: getQueryParam('waiter', 'phone'),
    aasm_state: getQueryParam('waiter', 'aasm_state'),
    call_provider: getQueryParam('waiter', 'call_provider'),
    channel: getQueryParam('waiter', 'channel'),
    created_at_from:
      getQueryParam('waiter', 'created_at_from') ||
      defaultOptions.created_at_from,
    created_at_to: getQueryParam('waiter', 'created_at_to'),
    estimated_up_time_at_from: getQueryParam(
      'waiter',
      'estimated_up_time_at_from'
    ),
    estimated_up_time_at_to: getQueryParam('waiter', 'estimated_up_time_at_to'),
    id: getQueryParam('waiter', 'id'),
    source: getQueryParam('waiter', 'source'),
    search: getQueryParam('waiter', 'search'),
    line_id: lineId || getQueryParam('waiter', 'line_id') || null,
    line_group_id: lineGroupId || null,
  };

  const [options, setOptions] = useState({
    ...paginationOptions,
    ...sortOptions,
    ...filterOptions,
    refreshing: false,
  });

  const changeOptions = useCallback(
    (newParams) => {
      setOptions((options) => ({ ...options, ...newParams }));
    },
    [setOptions]
  );

  const fetchLines = async (key, options) => {
    let endpoint = linesEndpoint;
    endpoint += lineGroupId ? '?line[line_group_id]=' + lineGroupId : '';

    const url = new URL(endpoint);
    const response = await fetch(url);
    const { data } = await response.json();
    return data.map(({ attributes }) => ({
      value: attributes.id,
      label: `${attributes.id} ${attributes.name}`,
    }));
  };

  const { data: lines, status: linesStatus } = useQuery(
    ['lines', {}],
    fetchLines,
    {
      retry: 1,
    }
  );

  const columns = useMemo(() => {
    const globalSearchTerms = !!options.search
      ? options.search?.split(' ')
      : [];

    return [
      {
        accessor: 'created_at',
        Header: 'Created At',
        Filter: ({ column }) => {
          return (
            <DateTimeColumnFilter
              column={column}
              fromDate={options.created_at_from}
              toDate={options.created_at_to}
              onChange={(values) => {
                setOptions((options) => ({
                  ...options,
                  created_at_from: values.startDate,
                  created_at_to: values.endDate,
                  page: 1,
                }));
              }}
            />
          );
        },
        Cell: ({ value }) => {
          const time = moment(value).format('HH:mm');
          const date = moment(value).format('DD MMMM YYYY');
          return (
            <>
              <span>{time}</span>
              <br />
              <span className="text-gray-400">{date}</span>
            </>
          );
        },
        width: '12.5%',
      },
      {
        accessor: 'estimated_up_time_at',
        Header: 'EUT',
        Filter: ({ column }) => {
          return (
            <DateTimeColumnFilter
              column={column}
              options={options}
              fromDate={options.estimated_up_time_at_from || null}
              toDate={options.estimated_up_time_at_to || null}
              onChange={(values) => {
                setOptions((options) => ({
                  ...options,
                  estimated_up_time_at_from: values.startDate,
                  estimated_up_time_at_to: values.endDate,
                  page: 1,
                }));
              }}
            />
          );
        },
        Cell: ({ value }) => {
          const time = moment(value).format('HH:mm');
          const date = moment(value).format('DD MMMM YYYY');
          return (
            <>
              <span>{time}</span>
              <br />
              <span className="text-gray-400">{date}</span>
            </>
          );
        },
        width: '12.5%',
      },
      {
        accessor: 'phone',
        Header: 'Phone',
        Filter: ({ column }) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({ ...options, phone: value, page: 1 }));
            }}
            value={options.phone}
          />
        ),
        Cell: ({ value, row }) => {
          return (
            <>
              <a
                className="text-indigo-700 text-bold"
                href={`/waiters/${row.original.id}`}>
                <Highlighter
                  searchWords={[options.phone, ...globalSearchTerms]}
                  autoEscape={true}
                  textToHighlight={value}
                />
                <br />
                <span className="text-gray-400">
                  <Highlighter
                    searchWords={[...globalSearchTerms]}
                    autoEscape={true}
                    textToHighlight={row.original.name}
                  />
                </span>
              </a>
            </>
          );
        },
        width: '15.5%',
      },
      {
        accessor: 'aasm_state',
        Header: 'State',
        Filter: ({ column }) => {
          const stateOptions = [
            { value: null, label: 'All' },
            { value: 'is_error', label: 'Error' },
            { value: 'is_refused', label: 'Refused' },
            { value: 'is_canceled', label: 'Canceled' },
            { value: 'is_missing_info', label: 'Mising Info' },
            { value: 'is_abandoned', label: 'Abandoned' },
            { value: 'is_finished', label: 'Finished' },
            { value: 'is_resolved', label: 'Resolved' },
            { value: 'is_waiting', label: 'Waiting' },
            { value: 'is_called', label: 'Called' },
            { value: 'is_connected', label: 'Connected' },
            { value: 'is_notified', label: 'Notified' },
            { value: 'is_not_answered', label: 'Not Answered' },
            { value: 'development', label: 'Development' },
            { value: 'is_timed_out', label: 'Timed Out' },
          ];

          const selectedOption = stateOptions.find(
            (option) => option.value === options.aasm_state
          ) || {
            value: null,
            label: 'All',
          };

          return (
            <SelectColumnFilter
              column={column}
              options={stateOptions}
              onChange={(value) =>
                setOptions((options) => ({
                  ...options,
                  aasm_state: value,
                  page: 1,
                }))
              }
              value={selectedOption}
            />
          );
        },
        filter: 'equals',
        Cell: ({ value }) => {
          const generateTagColor = (value) => {
            switch (value) {
              case 'is_error':
              case 'is_refused':
              case 'deleted':
              case 'is_canceled':
              case 'is_missing_info':
              case 'is_abandoned':
                return 'red';
              case 'is_finished':
              case 'is_resolved':
              case 'live':
                return 'green';
              case 'is_waiting':
              case 'is_deferred':
                return 'yellow';
              case 'is_called':
              case 'is_connected':
              case 'is_notified':
                return 'teal';
              case 'is_not_answered':
              case 'development':
              case 'is_timed_out':
                return 'orange';
            }
          };

          return (
            <span
              className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-${generateTagColor(
                value
              )}-100 text-${generateTagColor(value)}-800`}>
              <Highlighter
                searchWords={[...globalSearchTerms]}
                autoEscape={true}
                textToHighlight={value
                  .replace('is_', '')
                  .replace('_', ' ')
                  .toUpperCase()}
              />
            </span>
          );
        },
        width: '12.5%',
      },
      {
        accessor: 'channel',
        Header: 'Channel',
        Filter: ({ column }) => {
          const channelOptions = [
            { value: null, label: 'All' },
            { value: 'CallInExt', label: 'CallInExt' },
            { value: 'CallIn', label: 'CallIn' },
            { value: 'CallBack', label: 'CallBack' },
            { value: 'CallBackExt', label: 'CallBackExt' },
          ];

          const selectedOption = channelOptions.find(
            (option) => option.value === options.channel
          ) || {
            value: null,
            label: 'All',
          };

          return (
            <SelectColumnFilter
              column={column}
              options={channelOptions}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  channel: value,
                  page: 1,
                }));
              }}
              value={selectedOption}
            />
          );
        },
        Cell: ({ value }) => (
          <Highlighter
            searchWords={[...globalSearchTerms]}
            autoEscape={true}
            textToHighlight={value}
          />
        ),
        filter: 'includes',
        width: '12.5%',
      },
      {
        accessor: 'line_id',
        Header: 'Line',
        Filter: ({ column }) => {
          const lineOptions = [{ value: null, label: 'All' }, ...lines] || [];

          const selectedOption = lineOptions.find(
            (option) => option.value === parseInt(options.line_id)
          ) || {
            value: null,
            label: 'All',
          };

          return (
            linesStatus === 'success' && (
              <SelectColumnFilter
                column={column}
                options={lineOptions}
                onChange={(value) =>
                  setOptions((options) => ({
                    ...options,
                    line_id: value,
                    page: 1,
                  }))
                }
                menuWidth={400}
                value={selectedOption}
                isClearable={false}
              />
            )
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <Fragment>
              {createPath(original.path, [...globalSearchTerms])}
            </Fragment>
          );
        },
        width: '12.5%',
      },
      {
        accessor: 'source',
        Header: 'Source',
        Filter: ({ column }) => {
          const sourceOptions = [
            { value: null, label: 'All' },
            { value: 'API', label: 'API' },
            { value: 'App', label: 'App' },
            { value: 'Appointment', label: 'Appointment' },
            { value: 'Callback', label: 'Callback' },
            { value: 'Mobile', label: 'Mobile' },
            { value: 'Phone', label: 'Phone' },
            { value: 'Widget', label: 'Widget' },
          ];

          const selectedOption = sourceOptions.find(
            (option) => option.value === options.source
          ) || {
            value: null,
            label: 'All',
          };

          return (
            <SelectColumnFilter
              column={column}
              options={sourceOptions}
              onChange={(value) =>
                setOptions((options) => ({ ...options, source: value, page: 1 }))
              }
              value={selectedOption}
            />
          );
        },
        Cell: ({ value, row }) => {
          const properties = row.original.properties;
          return (
            <>
              {value}
              {properties && (
                <ul className="mt-2">
                  {Object.keys(properties).map((key) => (
                    <li key={key}>
                      <span
                        className="inline-flex items-center px-2.5 py-0.5 rounded-sm text-xs bg-indigo-100 text-indigo-800 mb-1 w-50 text-xs"
                        style={{ fontSize: '0.65rem' }}>
                        <pre
                          style={{
                            whiteSpace: 'break-spaces',
                          }}>
                          {`${key} → ${JSON.stringify(properties[key])}`}
                        </pre>
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </>
          );
        },
        width: '12.5%',
      },
      {
        accessor: 'call_provider',
        Header: 'Call Provider',
        Filter: ({ column }) => {
          const callProviderOptions = [
            { value: null, label: 'All' },
            { value: 'signalwire', label: 'SignalWire' },
            { value: 'twilio', label: 'Twilio' },
          ];

          const selectedOption = callProviderOptions.find(
            (option) => option.value === options.call_provider
          ) || {
            value: null,
            label: 'All',
          };

          return (
            <SelectColumnFilter
              column={column}
              options={callProviderOptions}
              onChange={(value) =>
                setOptions((options) => ({ ...options, call_provider: value, page: 1 }))
              }
              value={selectedOption}
            />
          );
        },
        Cell: ({ value }) => {
          return (
            <Fragment>
              {value}
            </Fragment>
          );
        },
        width: '12.5%',
      },
    ];
  }, [linesStatus, JSON.stringify(options)]);

  const fetchWaiters = async (key, options) => {
    const searchParams = queryParamsUrl('waiter', options, {});
    const url = new URL(waitersEndpoint);
    url.search = searchParams;
    const response = await fetch(url);
    const { data, meta } = await response.json();
    return { data: data.map((data) => data.attributes), meta };
  };

  useEffect(() => {
    const searchParams = queryParamsUrl('waiter', options, defaultOptions);
    insertUrlParam(searchParams);
  }, [JSON.stringify(options)]);

  const [refetchInterval, setRefetchInterval] = useState(10000);

  const { isLoading, resolvedData, isFetching } = usePaginatedQuery(
    ['waiters', options],
    fetchWaiters,
    {
      refetchInterval: refetchInterval,
      retry: 1,
    }
  );

  const tools = useMemo(
    () => [
      <GlobalFilter
        onSetGlobalFilter={(value) =>
          setOptions((options) => ({
            ...options,
            search: value,
          }))
        }
        searchValue={options.search}
        key="GlobalFilter"
      />,
      <CleanFiltersButton
        onResetFilters={() => {
          setAll(filterOptions, null);
          setOptions((options) => ({
            ...options,
            ...filterOptions,
          }));
        }}
        key="CleanFiltersButton"
      />,
      <CopyUrlButton key="CopyUrlButton" />,
      <PauseButton
        refetchInterval={refetchInterval}
        onSetRefetchInterval={setRefetchInterval}
        key="PauseButton"
      />,

      <RefreshButton
        isFetching={isFetching}
        key="RefreshButton"
        onRefreshTable={() =>
          setOptions((options) => ({
            ...options,
            refreshing: !options.refreshing,
          }))
        }
      />,
    ],
    [options.refreshing, options.search, isFetching, refetchInterval]
  );

  return !isLoading && linesStatus !== 'loading' ? (
    <>
      <DataTable
        data={resolvedData.data}
        columns={columns}
        tools={tools}
        setOptions={changeOptions}
        pageCount={resolvedData.meta.pages.total}
        sortOptions={sortOptions}
        paginationOptions={paginationOptions}
        filterOptions={filterOptions}
        fetching={isFetching}
      />
    </>
  ) : (
    <div className="box align-middle min-w-full">
      <TableLoader />
    </div>
  );
};

export default Waiters;
