import React, { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { debounce } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'rc-time-picker';
import SearchIcon from '../../icons/SearchIcon';
import moment from 'moment';

const timeToSeconds = (time) => {
  const a = time.format('mm:ss').split(':');
  const seconds = +a[0] * 60 + +a[1];
  return seconds;
};

const DefaultColumnFilter = ({ column: { Header, filterValue, setFilter } }) => (
  <input
    value={filterValue || ''}
    onChange={(e) => {
      setFilter(e.target.value || undefined);
    }}
    placeholder={Header}
    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring focus:ring-yellow focus:border-yellow-300 focus:z-10 sm:text-sm sm:leading-5"
  />
);

DefaultColumnFilter.propTypes = {
  column: PropTypes.object.isRequired,
};

const TextColumnFilter = ({ column: { Header }, onChange, value }) => {
  const [filter, setFilter] = useState(value);

  const handleChange = useCallback(() => onChange(filter), [filter]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') handleChange();
  };

  return (
    <input
      value={filter || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      onBlur={handleChange}
      onKeyDown={handleKeyDown}
      aria-label={Header}
      type="text"
      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring focus:ring-yellow focus:border-yellow-300 focus:z-10 sm:text-sm sm:leading-5"
      placeholder={Header}
    />
  );
};

TextColumnFilter.propTypes = {
  column: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

const NumberColumnFilter = ({ column: { Header }, onChange }) => {
  const [filter, setFilter] = useState('');

  const handleChange = useCallback(() => onChange(filter), [filter]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') handleChange();
  };

  return (
    <input
      value={filter || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      onBlur={handleChange}
      onKeyDown={handleKeyDown}
      aria-label={Header}
      type="number"
      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring focus:ring-yellow focus:border-yellow-300 focus:z-10 sm:text-sm sm:leading-5"
      placeholder={Header}
    />
  );
};

NumberColumnFilter.propTypes = {
  column: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SelectColumnFilter = ({
  column: { Header },
  options,
  onChange,
  menuWidth = 200,
  value,
}) => {
  const [selectedOption, setSelectedOption] = useState(value);

  const setFilter = (filterValue) => {
    const selectedOption = options.find(({ value }) => value === filterValue);
    setSelectedOption(selectedOption);
    onChange(selectedOption.value);
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      boxShadow: 'none',

      '&:hover': {
        borderColor: '#f5d05e',
        boxShadow: '0 0 0 3px rgba(250, 202, 21, 0.45);',
      },
    }),
    option: (styles) => {
      return {
        ...styles,

        ':active': {
          ...styles[':active'],
          backgroundColor: '#f5d05e',
          color: 'white',
        },
        ':hover': {
          ...styles[':active'],
          backgroundColor: '#f5d05e',
          color: 'white',
        },
      };
    },
    menu: (styles) => ({ ...styles, width: menuWidth }),
  };

  return (
    <Select
      value={selectedOption}
      options={options}
      onChange={(e) => {
        setFilter(e ? e.value : null);
      }}
      isClearable
      isSearchable
      placeholder={Header}
      styles={customStyles}
    />
  );
};

SelectColumnFilter.propTypes = {
  column: PropTypes.object.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

const DateTimeColumnFilter = ({ column, onChange, fromDate, toDate }) => {
  const [startDate, setStartDate] = useState(
    fromDate ? new Date(fromDate) : null
  );
  const [endDate, setEndDate] = useState(toDate ? new Date(toDate) : null);

  const fromInput = (
    <input
      type="text"
      className="appearance-none rounded-none relative flex-shrink  px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring focus:ring-yellow focus:border-yellow-300 focus:z-10 sm:text-sm sm:leading-5"
      placeholder="From"
      style={{ maxWidth: '100px' }}
    />
  );
  const toInput = (
    <input
      type="text"
      className="appearance-none rounded-none relative flex-shrink px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring focus:ring-yellow focus:border-yellow-300 focus:z-10 sm:text-sm sm:leading-5"
      placeholder="To"
      style={{ maxWidth: '100px' }}
    />
  );

  return (
    <div className="flex flex-row">
      <DatePicker
        customInput={fromInput}
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          onChange({ startDate: date, endDate });
        }}
        showTimeSelect
        dateFormat="dd/MM/yyyy H:mm"
        placeholderText="From"
        popperPlacement="bottom-end"
      />
      &nbsp;
      <DatePicker
        customInput={toInput}
        selected={endDate}
        onChange={(date) => {
          setEndDate(date);
          onChange({ endDate: date, startDate });
        }}
        showTimeSelect
        dateFormat="dd/MM/yyyy H:mm"
        placeholderText="To"
      />
    </div>
  );
};

const TimeColumnFilter = ({ column, onChange, greaterThan, lessThan }) => {
  const [startTime, setStartTime] = useState(
    greaterThan || moment().startOf('day')
  );
  const [endTime, setEndTime] = useState(lessThan || moment().startOf('day'));

  return (
    <div className="flex flex-row">
      <TimePicker
        defaultValue={startTime}
        showHour={false}
        placeholder="Greater than"
        onChange={(time) => {
          if (time) {
            setStartTime(time);
            const startTimeSeconds = timeToSeconds(time);
            onChange({
              duration_gt: startTimeSeconds,
            });
          } else {
            onChange({
              duration_gt: null,
            });
          }
        }}
        clearIcon={
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="rc-time-picker-clear">
            <path
              fill="#d2d2d2"
              d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        }
      />
      &nbsp;
      <TimePicker
        defaultValue={endTime}
        showHour={false}
        placeholder="Less than"
        onChange={(time) => {
          if (time) {
            setEndTime(time);
            const endTimeSeconds = timeToSeconds(time);
            onChange({
              duration_lt: endTimeSeconds,
            });
          } else {
            onChange({
              duration_lt: null,
            });
          }
        }}
        clearIcon={
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="rc-time-picker-clear">
            <path
              fill="#d2d2d2"
              d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        }
      />
    </div>
  );
};

DateTimeColumnFilter.propTypes = {
  column: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  fromDate: PropTypes.any,
  toDate: PropTypes.any,
};

function GlobalFilter({ onSetGlobalFilter, searchValue }) {
  const [value, setValue] = useState(searchValue);

  const onChange = useCallback(
    debounce((_value) => onSetGlobalFilter(_value || null), 1500),
    []
  );

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  return (
    <Fragment>
      <div className="relative">
        <SearchIcon
          className="h-4 w-4 absolute z-20"
          fill="#6d737d"
          style={{ top: '11px', left: '11px' }}
        />
        <input
          className="appearance-none rounded-none relative pl-8 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md rounded-b-md focus:outline-none focus:ring focus:ring-yellow focus:border-yellow-300 focus:z-10 sm:text-sm sm:leading-5 w-72"
          type="text"
          value={value || ''}
          placeholder={`Separate search terms with a space`}
          onChange={(e) => {
            setValue(e.target.value || undefined);
            onChange(e.target.value || null);
          }}
        />
      </div>
    </Fragment>
  );
}

export {
  DefaultColumnFilter,
  TextColumnFilter,
  NumberColumnFilter,
  SelectColumnFilter,
  DateTimeColumnFilter,
  TimeColumnFilter,
  GlobalFilter,
};
