import React, { useEffect, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'

const UserActivityGraph = ({callCenterId}) => {
  const [content, setContent] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const path = `/call_centers/${callCenterId}/user_activity`;
    fetch(path).then(response => response.json()).then(body => {
      const data = body.map(e => ({x: e.date, y: e.count}))
      setContent([{id: 'All Events', data}]);
      setIsLoaded(true);
    })
  }, []);
  if (isLoaded) {
    return <ResponsiveLine
      data={content}
      margin={{ top: 20, right: 30, bottom: 60, left: 30 }}
      axisBottom={{
        legendPosition: 'middle',
        legendOffset: 36,
        format: '%Y-%m-%d',
        tickValues: 'every 5 days',
      }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        precision: 'day',
      }}
      xFormat="time:%Y-%m-%d"
    />
  } else {
    return <div>Loading ...</div>
  }
}

export default UserActivityGraph;
