import React from 'react';
import CloseIcon from '../../icons/CloseIcon';

export default function CleanFiltersButton({onResetFilters}) {
  return (
    <button
      className="shadow align-middle px-2 py-2.5 rounded-md flex justify-center items-center"
      title="Reset Filters"
      onClick={onResetFilters}>
      <CloseIcon className="h-5 w-5" fill="#6d737d" />
    </button>
  );
}
