import React from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import moment from 'moment';
import OpenIcon from '../../icons/OpenIcon';

const backdropVariants = {
  hidden: {opacity: 0},
  visible: {opacity: 0.5},
};
const modalVariants = {
  hidden: {opacity: 0, y: -800},
  visible: {opacity: 1, y: 0},
};

const TwilioAlertsModal = ({twilioAlerts, setShowModal, showModal}) => {
  const twilioAlertsElements = twilioAlerts.map(
    (
      {id, error_code, message, date_created, request_method, request_url, sid},
      index
    ) => (
      <div key={id} className="flex relative">
        <div className="flex-grow">
          <div className="mb-2">
            <h2 className="font-medium title-font text-sm text-gray-900">
              {moment(date_created).format('DD MMMM YYYY')}{' '}

              <a
                target="_blank"
                className="text-red-500 font-bold ml-4"
                href={`https://www.twilio.com/docs/errors/${error_code}
              `}>{error_code}
              </a>
              {' '}
              {message}
            </h2>
          </div>

          <div className="font-medium title-font text-xs text-gray-400 mb-1">
            <span className="font-bold">{request_method}</span>
            {' '}
            {request_url}
          </div>
          <div className="text-right">
            <a
              className="text-sky-600 text-xs font-medium"
              target="_blank"
              href={` https://www.twilio.com/console/debugger/${sid}`}>
              Open in Twilio →
            </a>
          </div>
        </div>
      </div>
    )
  );
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {showModal && (
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto h-96">
                  <h2 className="text-xl font-bold leading-tight text-gray-900 mb-8">Errors</h2>
                  <section className="text-gray-600 body-font">
                    <div className="space-y-12">
                      {twilioAlertsElements}
                    </div>
                  </section>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b">
                  <button
                    className="text-sky-50 bg-sky-500 rounded font-bold uppercase px-4 py-1 text-xs"
                    type="button"
                    style={{transition: 'all .15s ease'}}
                    onClick={() => setShowModal(false)}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showModal && (
          <motion.div
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="fixed inset-0 z-40 bg-black"
            onClick={() => setShowModal(false)}></motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default TwilioAlertsModal;
