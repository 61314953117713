import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Graph } from './graph';

document.addEventListener('DOMContentLoaded', () => {
  const els = document.getElementsByClassName('call-flow-graph');
  Array.from(els).map((el) => {
    const tree = JSON.parse(el.dataset.tree);
    const height = tree.height * 60;
    const hierarchyType = el.dataset.hierarchy;
    const showCall = el.dataset.showCall == 'true';
    const root = createRoot(el);
    root.render(
      <Graph
        width="1050"
        height={height}
        tree={tree}
        hierarchyType={hierarchyType}
        showCall={showCall}
      />
    )
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const els = document.getElementsByClassName('call-log');
  Array.from(els).map((el) => {
    const log = JSON.parse(el.dataset.log);
    const root = createRoot(el);
    root.render(<CallLog log={log} />);
  });
});

const CallLog = ({ log }) => {
  const [open, toggle] = useState(false);
  return (
    <div>
      <button className="btn" onClick={() => toggle(!open)}>
        {open ? 'Hide call log' : 'Show call log'}
      </button>
      {open && <pre>{JSON.stringify(log, null, 2)}</pre>}
    </div>
  );
};
