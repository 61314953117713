import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import jstz from 'jstz';
import moment from 'moment-timezone';

document.addEventListener('DOMContentLoaded', () => {
  const lg = document.getElementById('line-group');
  if (lg) {
    const parent = document.querySelector('#waiters h3');

    if (parent) {
      const el = document.createElement('span');
      el.className = 'update-waiter-euts inline-flex pl-4';
      parent.appendChild(el);
      const root = createRoot(el);
      root.render(
        <UpdateWaiterEuts lineGroupId={lg.getAttribute('data-line-group-id')} />
      );
      Modal.setAppElement('#butterfly');
    }
  }
});

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const UpdateWaiterEuts = ({ lineGroupId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [running, setRunning] = useState(false);
  const [spacer, setSpacer] = useState(3);
  const [startTime, setStartTime] = useState(
    moment().add(30, 'minutes').format('YYYY-MM-DDTHH:mm')
  );
  const [onlyQueueInOpeningHours, setOnlyQueueInOpeningHours] = useState(true);
  const run = async (e) => {
    e.preventDefault();
    setRunning(true);
    await runUpdateWaiterEut({
      lineGroupId,
      startTime,
      spacer,
      onlyQueueInOpeningHours,
    });
    setRunning(false);
    setModalIsOpen(false);
  };
  return (
    <div>
      <button onClick={() => setModalIsOpen(true)} className="btn btn-orange btn-small text-xs">
        Reset Waiter EUTs
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}>
        <h3>Reset EUTs</h3>
        <form onSubmit={run}>
          <div className="form-group">
            <input
              type="datetime-local"
              className="form-control"
              required
              name="start-time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
            <label htmlFor="start-time">
              Date and time for the up time of the first waiter
            </label>
          </div>
          <div className="form-group">
            <input
              type="number"
              className="form-control"
              required
              name="spacer"
              value={spacer}
              onChange={(e) => setSpacer(e.target.value)}
            />
            <label htmlFor="spacer">
              Space <b>in minutes</b> between waiters
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="only-inside-opening-hours"
              checked={onlyQueueInOpeningHours}
              onChange={(e) => setOnlyQueueInOpeningHours(e.target.checked)}
            />
            <label
              htmlFor="only-inside-opening-hours"
              className="form-check-label">
              Ensure that waiters are only queued inside opening hours
            </label>
          </div>
          <input
            type="submit"
            value={running ? 'Running the reset process ...' : 'Reset EUTs'}
            className="btn btn-primary"
            disabled={running}
          />
          <button
            onClick={() => setModalIsOpen(false)}
            className="btn btn-default">
            Close
          </button>
        </form>
      </Modal>
    </div>
  );
};

const runUpdateWaiterEut = ({
  lineGroupId,
  startTime,
  spacer,
  onlyQueueInOpeningHours,
}) => {
  const tz = jstz.determine().name();
  const adjustedStartTime = moment.tz(startTime, tz).format();
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');
  return fetch(`/line_groups/${lineGroupId}/update_waiter_euts`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': token },
    body: JSON.stringify({
      reset_eut_start_time: adjustedStartTime,
      reset_eut_spacer: spacer,
      reset_eut_only_queue_in_opening_hours: onlyQueueInOpeningHours,
    }),
  });
};
