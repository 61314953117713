const urlParams = new URLSearchParams(window.location.search);

const insertUrlParam = (searchParams) => {
  if (history.pushState) {
    let baseUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;
    let newUrl = baseUrl;
    if (searchParams !== '') {
      newUrl = baseUrl + '?' + searchParams;
    }
    window.history.pushState({path: newUrl}, '', newUrl);
  }
};

const queryParamsUrl = (namespace, object, defaultObject) => {
  const namespacedOptions = {};

  Object.keys(object).forEach((key) => {
    if (
      object[key] !== null &&
      JSON.stringify(object[key]) !== JSON.stringify(defaultObject[key])
    ) {
      namespacedOptions[`${namespace}[${key}]`] = object[key];
    }
  });

  return new URLSearchParams(namespacedOptions).toString();
};

const getQueryParam = (namespace, keyword) => {
  return urlParams.get(`${namespace}[${keyword}]`);
};

export {urlParams, insertUrlParam, queryParamsUrl, getQueryParam};
