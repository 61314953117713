import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import { usePaginatedQuery } from 'react-query';
import DataTable from '../components/datatable';
import {
  SelectColumnFilter,
  DateTimeColumnFilter,
  TimeColumnFilter,
  TextColumnFilter,
  GlobalFilter,
} from '../components/filters';
import { callsEndpoint } from '../endpoints';
import moment from 'moment';
import TableLoader from '../components/datatable/TableLoader';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  insertUrlParam,
  queryParamsUrl,
  getQueryParam,
} from '../utils/queryParamsHelper';
import CopyUrlButton from '../components/CopyUrlButton';
import DownloadButton from '../components/DownloadButton';
import RefreshButton from '../components/RefreshButton';
import PauseButton from '../components/PauseButton';
import CleanFiltersButton from '../components/CleanFiltersButton';
import Highlighter from 'react-highlight-words';
import TwilioAlertsModal from '../components/modal';
import createPath from '../utils/pathHelper';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-time-picker/assets/index.css';
import { setAll } from '../utils/objHelper';

const TooltipWrapper = ({ text, searchTerms = [], maxLength = 20 }) => {
  return (
    <Fragment>
      {text.length > maxLength ? (
        <Fragment>
          <ReactTooltip id={text} />
          <span data-tooltip-id={text} data-tooltip-content={text}>
            <Highlighter
              searchWords={searchTerms}
              autoEscape={true}
              textToHighlight={`${text.substring(0, maxLength - 3)}...`}
            />
          </span>
        </Fragment>
      ) : (
        <Highlighter
          searchWords={searchTerms}
          autoEscape={true}
          textToHighlight={text}
        />
      )}
    </Fragment>
  );
};

const callUrl = ({ region, provider, call_sid }) => {
  const region_id = region == 'us' ? 'us1' : 'ie1';

  if (provider.toLowerCase() === 'twilio') {
    return `https://www.twilio.com/console/voice/calls/logs/${call_sid}?x-target-region=${region_id}`;
  }

  return `https://dt.eu-signalwire.com/laml-calls/${call_sid}`;
};

const Calls = () => {
  const [showModal, setShowModal] = useState(false);
  const [twilioAlerts, setTwilioAlerts] = useState([]);

  const defaultOptions = {
    ...JSON.parse(document.getElementById('calls').dataset.defaultQueryParams),
    refreshing: false,
  };

  const paginationOptions = {
    page: parseInt(getQueryParam('call', 'page')) || defaultOptions.page,
    per_page:
      parseInt(getQueryParam('call', 'per_page')) || defaultOptions.per_page,
  };

  const sortOptions = {
    sort_col: getQueryParam('call', 'sort_col') || defaultOptions.sort_col,
    sort_dir: getQueryParam('call', 'sort_dir') || defaultOptions.sort_dir,
  };

  const filterOptions = {
    id: getQueryParam('call', 'id'),
    from_phone: getQueryParam('call', 'from_phone'),
    to_phone: getQueryParam('call', 'to_phone'),
    region: getQueryParam('call', 'region'),
    provider: getQueryParam('call', 'provider'),
    started_at_from: getQueryParam('call', 'started_at_from'),
    started_at_to: getQueryParam('call', 'started_at_to'),
    duration_gt: getQueryParam('call', 'duration_gt'),
    duration_lt: getQueryParam('call', 'duration_lt'),
    status: getQueryParam('call', 'status'),
    leg: getQueryParam('call', 'leg'),
    call_sid: getQueryParam('call', 'call_sid'),
    search: getQueryParam('call', 'search'),
  };

  const [options, setOptions] = useState({
    ...paginationOptions,
    ...sortOptions,
    ...filterOptions,
    refreshing: false,
  });

  const changeOptions = useCallback(
    (newParams) => {
      setOptions((options) => ({ ...options, ...newParams }));
    },
    [setOptions]
  );

  const columns = useMemo(() => {
    const globalSearchTerms = !!options.search
      ? options.search?.split(' ')
      : [];

    return [
      {
        accessor: 'started_at',
        Header: 'Time',
        Filter: ({ column }) => {
          return (
            <DateTimeColumnFilter
              column={column}
              fromDate={options.started_at_from || null}
              toDate={options.started_at_to || null}
              onChange={(values) => {
                setOptions((options) => ({
                  ...options,
                  started_at_from: values.startDate,
                  started_at_to: values.endDate,
                  page: 1,
                }));
              }}
            />
          );
        },
        filter: 'includes',
        Cell: ({ value, row }) => {
          const { original } = row;
          const startTime = moment(value).format('HH:mm');
          const endTime = original.ended_at
            ? moment(original.ended_at).format('HH:mm')
            : null;
          const date = moment(value).format('DD MMMM YYYY');
          return (
            <>
              <span>{startTime}</span> →{' '}
              {endTime ? (
                <span>{endTime}</span>
              ) : (
                <span className="text-gray-400">In progress</span>
              )}
              <br />
              <small className="text-gray-400">{date}</small>
            </>
          );
        },
        width: '10%',
      },

      {
        accessor: 'from_phone',
        Header: 'From',
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              column={column}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  from_phone: value,
                  page: 1,
                }));
              }}
              value={options.from_phone}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;
          const fromPath = createPath(original.from_path, [
            ...globalSearchTerms,
          ]);
          return (
            <Fragment>
              <span className="block mb-1">
                <TooltipWrapper
                  text={original.from_phone}
                  searchTerms={[...globalSearchTerms, options.from_phone]}
                />
              </span>
              {fromPath}
            </Fragment>
          );
        },
        width: '10%',
      },
      {
        accessor: 'to_phone',
        Header: 'To',
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              column={column}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  to_phone: value,
                  page: 1,
                }));
              }}
              value={options.to_phone}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;

          const toPath = createPath(original.to_path, [...globalSearchTerms]);

          return (
            <Fragment>
              <span className="block mb-1">
                <TooltipWrapper
                  text={original.to_phone}
                  searchTerms={[...globalSearchTerms, options.to_phone]}
                />
              </span>
              {toPath}
            </Fragment>
          );
        },
        width: '10%',
      },

      {
        accessor: 'duration',
        Header: 'Duration',
        Filter: ({ column }) => {
          const greaterThan = moment()
            .startOf('day')
            .seconds(options.duration_gt);
          const lessThan = moment().startOf('day').seconds(options.duration_lt);
          return (
            <TimeColumnFilter
              column={column}
              greaterThan={greaterThan}
              lessThan={lessThan}
              onChange={(values) => {
                setOptions((options) => ({
                  ...options,
                  ...values,
                  page: 1,
                }));
              }}
            />
          );
        },
        Cell: ({ value, row }) => {
          return (
            <a className="text-bold">
              {value
                ? moment().startOf('day').seconds(value).format('mm:ss')
                : '-'}
            </a>
          );
        },
        width: '10%',
      },
      {
        accessor: 'status',
        Header: 'Status',
        Filter: ({ column }) => {
          const statusOptions = [
            { value: null, label: 'All' },
            { value: 'queued', label: 'Queued' },
            { value: 'ringing', label: 'Ringing' },
            { value: 'in-progress', label: 'In Progress' },
            { value: 'busy', label: 'Busy' },
            { value: 'failed', label: 'Failed' },
            { value: 'completed', label: 'Completed' },
            { value: 'no-answer', label: 'No Answer' },
            { value: 'canceled', label: 'Canceled' },
          ];

          const selectedOption = statusOptions.find(
            (option) => option.value === options.status
          ) || {
            value: null,
            label: 'All',
          };

          return (
            <SelectColumnFilter
              column={column}
              options={statusOptions}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  status: value,
                  page: 1,
                }));
              }}
              value={selectedOption}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;
          const generateTagColor = (value) => {
            switch (value) {
              case 'no-answer':
              case 'failed':
              case 'busy':
              case 'canceled':
                return 'red';
              case 'completed':
                return 'green';
              case 'ringing':
              case 'in-progress':
                return 'cyan';
              case 'queued':
                return 'yellow';
            }
          };

          return (
            <div>
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-${generateTagColor(
                  value
                )}-100 text-${generateTagColor(value)}-800`}>
                <Highlighter
                  searchWords={[...globalSearchTerms]}
                  autoEscape={true}
                  textToHighlight={value.replace('-', ' ').toUpperCase()}
                />
              </span>
              {original.twilio_alerts.length > 0 && (
                <span
                  onClick={() => {
                    setShowModal(true);
                    setTwilioAlerts(original.twilio_alerts);
                  }}
                  className={`inline-flex items-center px-3 py-1 rounded-full text-lg font-medium leading-4 bg-red-200 text-white ml-2 cursor-pointer`}>
                  !
                </span>
              )}
            </div>
          );
        },
        width: '10%',
      },

      {
        accessor: 'call_sid',
        Header: 'SID',
        Filter: ({ column }) => (
          <TextColumnFilter
            column={column}
            onChange={(value) =>
              setOptions((options) => ({ ...options, call_sid: value, page: 1 }))
            }
            value={options.call_sid}
          />
        ),
        Cell: ({ value, row }) => {
          const { original } = row;
          const sidLink = callUrl(original);

          return (
            <>
              <span className="block mb-1 font-semibold">
                {original.leg &&
                  original.leg.charAt(0).toUpperCase() +
                  original.leg.slice(1).split('_').join(' ')}
              </span>
              <small className="flex flex-row">
                <a
                  className="text-indigo-700 text-bold"
                  target="_blank"
                  href={sidLink}
                  title={value}>
                  <Highlighter
                    searchWords={[...globalSearchTerms]}
                    autoEscape={true}
                    textToHighlight={value}
                  />
                </a>
                <CopyToClipboard
                  text={value}
                  onCopy={() =>
                    toast.success('Copied to clipboard!', {
                      position: 'top-right',
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    })
                  }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="w-3 h-3 ml-1 mt-1 cursor-pointer">
                    <path
                      fill="#5145cd"
                      d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path>
                  </svg>
                </CopyToClipboard>
              </small>
            </>
          );
        },
        width: '10%',
      },

      {
        accessor: 'region',
        Header: 'Region',
        Filter: ({ column }) => {
          return (
            <TextColumnFilter
              column={column}
              onChange={(value) => {
                setOptions((options) => ({
                  ...options,
                  region: value,
                  page: 1,
                }));
              }}
              value={options.region}
            />
          );
        },
        Cell: ({ value, row }) => {
          const { original } = row;

          return (
            <Fragment>
              <span className="block mb-1">
                <TooltipWrapper
                  text={original.region}
                  searchTerms={[...globalSearchTerms, options.region]}
                />
              </span>
            </Fragment>
          );
        },
        width: '10%',
      },

      {
        accessor: 'provider',
        Header: 'Provider',
        Filter: ({ column }) => (
          <TextColumnFilter
            column={column}
            onChange={(value) => {
              setOptions((options) => ({ ...options, provider: value, page: 1 }));
            }}
            value={options.provider}
          />
        ),
        Cell: ({ value, row }) => {
          return (
            <div className="text-grey-700">
              <Highlighter
                searchWords={[options.provider, ...globalSearchTerms]}
                autoEscape={true}
                textToHighlight={
                  value
                }
              />
            </div>
          );
        },
        width: '15.5%',
      },
    ];
  }, [JSON.stringify(options)]);

  const fetchCalls = async (key, options) => {
    const searchParams = queryParamsUrl('call', options, {});
    const url = new URL(callsEndpoint);
    url.search = searchParams;
    const response = await fetch(url);
    const { data, meta } = await response.json();
    return { data: data.map((data) => data.attributes), meta };
  };

  useEffect(() => {
    const searchParams = queryParamsUrl('call', options, defaultOptions);
    insertUrlParam(searchParams);
  }, [JSON.stringify(options)]);

  const [refetchInterval, setRefetchInterval] = useState(10000);

  const { isLoading, resolvedData, isFetching } = usePaginatedQuery(
    ['calls', options],
    fetchCalls,
    {
      refetchInterval: refetchInterval,
      retry: 1,
    }
  );

  const tools = useMemo(
    () => [
      <GlobalFilter
        onSetGlobalFilter={(value) =>
          setOptions((options) => ({
            ...options,
            search: value,
          }))
        }
        searchValue={options.search}
        key="GlobalFilter"
      />,
      <CleanFiltersButton
        onResetFilters={() => {
          setAll(filterOptions, null);
          setOptions((options) => ({
            ...options,
            ...filterOptions,
          }));
        }}
        key="CleanFiltersButton"
      />,
      <CopyUrlButton key="CopyUrlButton" />,
      <PauseButton
        refetchInterval={refetchInterval}
        onSetRefetchInterval={setRefetchInterval}
        key="PauseButton"
      />,
      <RefreshButton
        isFetching={isFetching}
        key="RefreshButton"
        onRefreshTable={() =>
          setOptions((options) => ({
            ...options,
            refreshing: !options.refreshing,
          }))
        }
      />,
      <DownloadButton
        key="DownloadButton"
        onDownload={() => {
          const { page, per_page, ...rest } = options;
          const {
            page: defaultOptionsPage,
            per_page: defaultOptionsPerPage,
            ...defaultOptionsRest
          } = defaultOptions;
          const searchParams = queryParamsUrl('call', rest, defaultOptionsRest);

          const [currentUrl, queryParams] = window.location.href.split('?');

          location.href = currentUrl + '.csv?' + searchParams;
        }}
      />,
    ],
    [options.refreshing, options.search, isFetching, refetchInterval]
  );

  return !isLoading ? (
    <>
      <TwilioAlertsModal
        setShowModal={setShowModal}
        twilioAlerts={twilioAlerts}
        showModal={showModal}
      />

      <DataTable
        data={resolvedData.data}
        columns={columns}
        setOptions={changeOptions}
        pageCount={resolvedData.meta.pages.total}
        sortOptions={sortOptions}
        paginationOptions={paginationOptions}
        fetching={isFetching}
        tools={tools}
        filterOptions={filterOptions}
      />
      <ToastContainer />
    </>
  ) : (
    <div className="box align-middle min-w-full">
      <TableLoader />
    </div>
  );
};

export default Calls;
