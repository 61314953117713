import React from 'react';
import PlayIcon from '../../icons/PlayIcon';
import PauseIcon from '../../icons/PauseIcon';

export default function PauseButton({refetchInterval, onSetRefetchInterval}) {
  return (
    <button
      className="shadow align-middle px-2 py-2.5 rounded-md flex justify-center items-center"
      title={refetchInterval ? 'Pause re-fetching' : 'Start re-fetching'}
      onClick={() =>
        onSetRefetchInterval((refetchInterval) =>
          refetchInterval ? false : 5000
        )
      }>
      {refetchInterval ? (
        <PauseIcon className="h-5 w-5" fill="#6d737d" />
      ) : (
        <PlayIcon className="h-5 w-5" fill="#6d737d" />
      )}
    </button>
  );
}
