import React from 'react';
import RefreshIcon from '../../icons/RefreshIcon';
import {motion} from 'framer-motion';

const RefreshButton = ({isFetching, onRefreshTable}) => {
  return (
    <button
      className="shadow align-middle px-2 py-2.5 rounded-md flex justify-center items-center"
      title={isFetching ? 'Refreshing...' : 'Refresh'}
      disabled={isFetching}
      onClick={onRefreshTable}>
      {isFetching ? (
        <motion.div
          animate={{rotate: 360}}
          transition={{duration: 1, repeat: Infinity}}>
          <RefreshIcon className="h-5 w-5" fill="#a3a7ad" />
        </motion.div>
      ) : (
        <RefreshIcon className="h-5 w-5" fill="#6d737d" />
      )}
    </button>
  );
};

export default RefreshButton;
