import React, { useState } from "react";
import { createRoot } from 'react-dom/client';

document.addEventListener("DOMContentLoaded", () => {
  const el = document.getElementById("profile-menu");
  if (el) {
    const profileImageUrl = el.dataset.profileImageUrl;
    const root = createRoot(el);
    root.render(<ProfileMenu profileImageUrl={profileImageUrl} />);
  }
});

const ProfileMenu = ({ profileImageUrl }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div>
        <button onClick={() => setOpen(!open)} className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid" id="user-menu" aria-label="User menu" aria-haspopup="true">
          <img className="h-8 w-8 rounded-full" src={profileImageUrl} alt="Profile picture" />
        </button>

        <div className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg ${open ? "" : "hidden"}`}>
          <div className="py-1 rounded-md bg-white shadow-xs">
            <a href="/sign_out" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out">Sign out</a>
          </div>
        </div>
      </div>
    </div>
  );
};
