import React, { Fragment } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Highlighter from 'react-highlight-words';

const createPathUrl = ({ type, id }) => {
  switch (type) {
    case 'Line':
      return `/lines/${id}`;
    case 'ForwardedCall':
      return `/forwarded_calls/${id}`;
    case 'TwilioNumber':
      return `/numbers/${id}`;
    case 'LineGroup':
      return `/line_groups/${id}`;
    case 'CallCenter':
      return `/call_centers/${id}`;
    case 'Waiter':
      return `/waiters/${id}`;
  }
};

const createPath = (paths, searchTerms) => {
  let fullPath = '';
  let pathUrl = [];

  paths.forEach((path, index) => {
    const url = createPathUrl(path);
    const inMiddle = index !== 0 && index !== paths.length - 1;
    const equalsSearchTerm = !!searchTerms.find(
      (searchTerm) =>
        searchTerm &&
        path?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    let pathName = inMiddle
      ? (fullPath + path.name).length > 30 && !equalsSearchTerm
        ? `${path.name.substring(0, 10)}...`
        : path.name
      : path.name;
    const showRightArrow = index !== paths.length - 1;
    pathUrl = [
      ...pathUrl,
      <a href={url} className="text-indigo-700 text-bold" key={index}>
        <Highlighter
          searchWords={searchTerms}
          autoEscape={true}
          textToHighlight={pathName}
        />
      </a>,
      showRightArrow ? ' > ' : '',
    ];
    fullPath += path.name;
    fullPath += showRightArrow ? ' > ' : '';
  });

  return (
    paths && (
      <Fragment>
        <ReactTooltip id={fullPath} />
        <small>
          <span data-tooltip-id={fullPath} data-tooltip-content={fullPath}>{pathUrl}</span>
        </small>
      </Fragment>
    )
  );
};

export default createPath;
