import * as React from 'react';

function DownloadIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="511pt"
      viewBox="-53 1 511 511.999"
      width="511pt"
      {...props}>
      <path d="M276.41 3.957C274.062 1.484 270.844 0 267.508 0H67.778C30.921 0 .5 30.3.5 67.152v377.692C.5 481.699 30.922 512 67.777 512h271.086c36.856 0 67.278-30.3 67.278-67.156V144.94c0-3.214-1.485-6.304-3.586-8.656zm3.586 39.7l84.469 88.671h-54.91c-16.325 0-29.559-13.11-29.559-29.433zm58.867 443.609H67.777c-23.125 0-42.543-19.168-42.543-42.422V67.152c0-23.125 19.293-42.418 42.543-42.418h187.485v78.16c0 30.051 24.242 54.168 54.293 54.168h71.851v287.782c0 23.254-19.293 42.422-42.543 42.422zm0 0" />
      <path d="M305.102 401.934H101.539c-6.8 0-12.367 5.562-12.367 12.367 0 6.8 5.566 12.367 12.367 12.367h203.688c6.8 0 12.367-5.566 12.367-12.367 0-6.805-5.567-12.367-12.492-12.367zm0 0M194.293 357.535c2.352 2.473 5.566 3.957 9.027 3.957 3.465 0 6.68-1.484 9.028-3.957l72.472-77.789c4.7-4.95 4.328-12.863-.617-17.437-4.95-4.7-12.863-4.332-17.437.617l-51.079 54.785V182.664c0-6.805-5.566-12.367-12.367-12.367-6.8 0-12.367 5.562-12.367 12.367v135.047L140 262.926c-4.7-4.946-12.492-5.317-17.438-.617-4.945 4.699-5.316 12.492-.617 17.437zm0 0" />
    </svg>
  );
}

export default DownloadIcon;
