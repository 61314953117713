import React, {Fragment} from 'react';
import DownloadIcon from '../../icons/DownloadIcon';

const DownloadButton = ({onDownload}) => {
  return (
    <Fragment>
      <button
        className="shadow align-middle px-2 py-2.5 rounded-md flex justify-center items-center"
        title="Download as CSV"
        onClick={onDownload}>
        <DownloadIcon className="h-5 w-5" fill="#6d737d" />
      </button>
    </Fragment>
  );
};

export default DownloadButton;
