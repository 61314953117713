import React from 'react';
import { createRoot } from 'react-dom/client';
import TextMessages from './TextMessages';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const TextMessagesApp = () => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <TextMessages />
      <ReactQueryDevtools initialIsOpen />
    </ReactQueryCacheProvider>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('text_messages');

  if (el) {
    const root = createRoot(el);
    root.render(<TextMessagesApp />);
  }
});
