import React from 'react';
import { createRoot } from 'react-dom/client';
import Waiters from './Waiters';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const WaitersApp = ({ lineGroupId, lineId }) => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Waiters lineGroupId={lineGroupId} lineId={lineId} />
      <ReactQueryDevtools initialIsOpen />
    </ReactQueryCacheProvider>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('waiters');

  if (el) {
    const lineGroupId = el.dataset.linegroup;
    const lineId = el.dataset.line;
    const root = createRoot(el);
    root.render(
      <WaitersApp lineGroupId={lineGroupId} lineId={lineId} />,
    );
  }
});
