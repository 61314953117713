import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';

document.addEventListener('DOMContentLoaded', () => {
  const els = document.getElementsByClassName('bot-session');
  Array.from(els).map((el) => {
    const log = JSON.parse(el.dataset.session);
    const root = createRoot(el);
    root.render(<Session log={log} />);
  });
});

const Session = ({ log }) => {
  const [open, toggle] = useState(false);
  return (
    <div>
      <button className="btn" onClick={() => toggle(!open)}>
        {open ? 'Hide session' : 'Show session'}
      </button>
      {open && <pre>{JSON.stringify(log, null, 2)}</pre>}
    </div>
  );
};
