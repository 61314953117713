import * as React from 'react';

function OpenIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.004 511.004"
      {...props}>
      <path d="M408.2 160.078H203.85l-44.559-34.958H0v321.827c0 25.939 21.086 47.595 47.015 48.272l353.829.199h.028c21.431-.001 40.316-14.442 45.931-35.127l63.791-235.027H408.2zM50.664 465.221l-3.062-.002v.005C38.042 464.87 30 456.543 30 446.947V155.12h118.928l44.559 34.958H378.2v35.187H133.904L73.83 446.4c-2.913 10.723-12.269 18.222-23.166 18.821zm420.701-209.956l-53.516 197.17c-2.075 7.646-9.056 12.984-16.979 12.984h-.01l-302.34-.171a56.104 56.104 0 004.262-10.982l54.061-199.001zM368.658 45.585c33.493 0 61.794 22.574 70.541 53.299l-17.785-13.815-18.402 23.691 53.996 41.942 53.996-41.942L492.6 85.069l-21.965 17.062c-8.05-49.023-50.71-86.547-101.977-86.547-56.986 0-103.349 46.362-103.349 103.349h30c.001-40.444 32.905-73.348 73.349-73.348z" />
      <path d="M187.495 435.182l.03-30 181.118.18-.03 30z" />
    </svg>
  );
}

export default OpenIcon;
