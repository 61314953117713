import React, {Fragment} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ToastContainer, toast} from 'react-toastify';
import CopyIcon from '../../icons/CopyIcon';

const CopyUrlButton = () => {
  return (
    <Fragment>
      <CopyToClipboard
        text={window.location.href}
        onCopy={() => {
          console.log(window.location.href);
          toast.success('Copied to clipboard!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }}>
        <button
          className="shadow align-middle px-2 py-2.5 rounded-md flex justify-center items-center"
          title="Copy url">
          <CopyIcon className="h-5 w-5" fill="#6d737d" />
        </button>
      </CopyToClipboard>
      <ToastContainer />
    </Fragment>
  );
};

export default CopyUrlButton;
