import React, { useState } from "react";
import { createRoot } from 'react-dom/client';

import { StatBox, useInterval } from "../lib";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.getElementById("dashboard-widgets");
  if (el) {
    const root = createRoot(el);
    root.render(<Dashboard />);
  }
});

const Dashboard = () => (
  <div>
    <div className="mt-5 my-8 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-4">
      <CallFlowStats />
      <WaiterStats />
      <SMSStats />
      <TwilioActiveCallStats />
    </div>
  </div>
);

const TwilioActiveCallStats = () => {
  const [activeCalls, setActiveCalls] = useState(null);
  useInterval(() => {
    fetch("/widgets/twilio_active_calls")
      .then(response => response.json())
      .then(({ count }) => {
        setActiveCalls(count);
      });
  }, 10);
  return (
    <StatBox
      title="Active calls"
      isLoading={activeCalls == null}
      border={true}
    >
      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
        {activeCalls}
        <span className="ml-2 text-sm leading-5 font-medium text-gray-500">via Twilio</span>
      </div>
    </StatBox>
  );
};

const SMSStats = () => {
  const [smsTotals, setSmsTotals] = useState({});
  useInterval(() => {
    fetch("/widgets/sms_sent")
      .then(response => response.json())
      .then((totals) => {
        setSmsTotals(totals);
      });
  }, 10);
  return (
    <StatBox title="Text messages" border={true} isLoading={smsTotals == {}}>
      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
        {smsTotals.today}
        <span className="ml-2 text-sm leading-5 font-medium text-gray-500">from {smsTotals.yesterday}</span>
      </div>
      <Delta is={smsTotals.today} was={smsTotals.yesterday}></Delta>
    </StatBox>
  );
};

const CallFlowStats = () => {
  const [callTotals, setCallTotals] = useState({});
  useInterval(() => {
    fetch("/widgets/call_flow")
      .then(response => response.json())
      .then((totals) => {
        setCallTotals(totals);
      });
  }, 10);
  return (
    <StatBox
      title="Total calls today"
      isLoading={callTotals == {}}
    >
      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
        {callTotals.today}
        <span className="ml-2 text-sm leading-5 font-medium text-gray-500">from {callTotals.yesterday}</span>
      </div>
      <Delta is={callTotals.today} was={callTotals.yesterday}></Delta>
    </StatBox>
  );
};

const WaiterStats = () => {
  const [waiterTotals, setwaiterTotals] = useState({});
  useInterval(() => {
    fetch("/widgets/waiters")
      .then(response => response.json())
      .then((totals) => {
        setwaiterTotals(totals);
      });
  }, 10);
  return (
    <StatBox
      title="Waiters"
      border={true}
      isLoading={waiterTotals == {}}
    >
      <div className="flex items-baseline text-2xl leading-8 font-semibold text-indigo-600">
        {waiterTotals.today}
        <span className="ml-2 text-sm leading-5 font-medium text-gray-500">from {waiterTotals.yesterday}</span>
      </div>
      <Delta is={waiterTotals.today} was={waiterTotals.yesterday}></Delta>
    </StatBox>
  );
};

const Up = ({ delta }) => {
  return <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
    <svg className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
      <path fillRule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
    </svg>
    <span className="sr-only">
      Increased by
    </span>
    {delta}
  </div>;
};

const Down = ({ delta }) => {
  return <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-red-100 text-red-800 md:mt-2 lg:mt-0">
    <svg className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
      <path fillRule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clipRule="evenodd" />
    </svg>
    <span className="sr-only">
      Decreased by
    </span>
    {delta}
  </div>;
};

const Delta = ({ is, was }) => {
  if (is == undefined || was == undefined)
    return "";
  if (is >= was)
    return <Up delta={is - was} />;

  return <Down delta={-(is - was)} />;
};
