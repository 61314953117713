import React from 'react';
import { createRoot } from 'react-dom/client';
import Numbers from './Numbers';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const NumbersApp = () => {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Numbers />
      <ReactQueryDevtools initialIsOpen />
    </ReactQueryCacheProvider>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('numbers');
  if (el) {
    const root = createRoot(el);
    root.render(<NumbersApp />);
  }
});
